.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-top: 5px;
}

.checkbox {
  & span {
    font-weight: 400 !important;
  }
}

.title {
  color: #232631;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
