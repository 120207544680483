@import 'sources/responsive.scss';

.currentProgram {
  width: 100%;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 16px;

  @include _680 {
    margin-bottom: 10px;
  }

  @include _480 {
    gap: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.icon {
  display: flex;
  align-items: center;
  @include _480 {
    display: none !important;
  }
}

.goalInfo {
  padding-top: 15px;
  padding-bottom: 15px;

  & > div:first-child {
    font-size: 14px;
    line-height: 19px;
    color: #232631;

    @include _480 {
      font-size: 12px;
    }
  }

  & > div:last-child {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #222222;

    @include _480 {
      font-size: 14px;
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  margin-inline-end: 34px;

  div:not(:first-child) {
    margin-inline-start: 10px;
  }

  div {
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #004d98;

    & > span {
      margin-inline-start: 2px;
    }

    @include _480 {
      font-size: 12px;
    }
  }

  @include _480 {
    margin-inline-end: 5px;
  }
}
