.icon {
  display: block;
  height: 1rem;
  width: auto;
}

.button {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
  }
}
