@import 'src/sources/styles/variables.module.scss';
@import 'src/sources/responsive.scss';

@mixin tableList {
  table {
    border-collapse: separate !important;
    border-spacing: 0 10px !important;

    & tbody {
      & td {
        border-top: 1px solid $borderColor !important;
        border-bottom: 1px solid $borderColor !important;

        &:first-child {
          border-left: 1px solid $borderColor !important;
        }

        &:last-child {
          border-right: 1px solid $borderColor !important;
        }
      }
    }

    & .ant-table-cell {
      &.ant-table-selection-column {
        border-spacing: initial !important;
      }

      & .ant-checkbox-wrapper {
        border-spacing: initial !important;
      }
    }
  }

  tr {
    & td {
      border: 0 !important;

      &:first-child {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
      &:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }

      &.ant-table-cell-row-hover {
        background-color: #f4f4f4 !important;
      }

      @include _600 {
        font-size: 13px !important;
      }
    }
    background-color: #f4f4f4 !important;

    &.ant-table-row-selected td {
      background-color: #f4f4f4 !important;
    }
  }
}

.default_users_table {
  @include tableList;

  & table {
    & tbody {
      & .ant-table-expanded-row.ant-table-expanded-row-level-1 {
        & > td {
          border: 0 !important;
        }
      }
    }
  }

  & .ant-table {
    background-color: transparent !important;
    background: transparent !important;
  }

  & thead {
    & tr {
      background: transparent !important;
    }

    & th {
      background: transparent !important;
      color: #8c8c8c !important;
      font-size: 13px !important;
      font-weight: 500 !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;

      &::before {
        display: none !important;
      }
    }

    & .ant-table-filter-trigger {
      top: -2px !important;
    }
  }

  .ant-table-cell-with-append {
    padding-inline-end: 0 !important;
  }

  .ant-table-cell:nth-child(2) {
    padding-inline-start: 0 !important;
  }

  .ant-table-row {
    background-color: #fff !important;
    border: 1px solid red !important;

    & .ant-table-cell-row-hover {
      background-color: #fff !important;
    }
  }

  .ant-table-expanded-row {
    & .ant-table-cell {
      background: $bgColor !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      & tbody {
        & tr {
          background-color: #fff !important;
          border-radius: 6px;
        }

        & td {
          padding: 15px !important;
          background: transparent !important;
        }
      }
    }
  }
}

// fix border radius in ant design table rows
body[dir='rtl'] {
  .default_users_table,
  .inviteTraineesTable,
  .importedTraineesCVSModal {
    tr {
      & td {
        &:first-child {
          border-top-right-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-right: 1px solid $borderColor !important;
          border-left: 0 !important;
        }
        &:last-child {
          border-top-left-radius: 10px !important;
          border-bottom-left-radius: 10px !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-left: 1px solid $borderColor !important;
          border-right: 0 !important;
        }
      }
    }
  }
}

// table on invite trainee block / program create screen
.styles_table__1xbhE {
  .ant-table-cell {
    @include _400 {
      padding: 16px 8px !important;
    }
  }
}

.grey-rows {
  & .ant-table-tbody > tr.ant-table-row:hover td {
    background-color: #f4f4f4 !important;
    background: #f4f4f4 !important;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.transparent-table {
  & .ant-table {
    background: transparent !important;
  }

  & td {
    background-color: transparent !important;
  }
}

.inviteTraineesTable {
  @include tableList();

  & thead {
    & tr,
    th {
      background-color: #fff !important;
      border: 0 !important;
    }

    & th {
      padding-top: 5px;
      padding-bottom: 5px;

      color: #8c8c8c !important;
      font-size: 13px !important;
      font-weight: 500 !important;

      &::before {
        display: none !important;
      }
    }
  }
}

.importedTraineesCVSModal {
  width: 100% !important;
  max-width: 1000px !important;

  @include tableList();

  & .ant-table-placeholder {
    background-color: #fff !important;
  }

  & .ant-modal-content {
    width: 100% !important;
    max-width: 1000px !important;
  }

  & .ant-modal-header {
    background-color: $bgColor !important;
  }

  & .ant-modal-footer {
    background-color: $bgColor !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .ant-modal-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.resetExpandableRow {
  & tr.ant-table-expanded-row {
    border: none !important;

    & > td.ant-table-cell {
      background: transparent !important;
      border-radius: 0 !important;
      padding: 0 !important;
      border: none !important;
    }
  }
}

.table-sort-reverse {
  & .ant-table-column-sorters {
    flex-direction: row-reverse !important;
    gap: 10px !important;
  }
}

.ant-table-filter-column {
  justify-content: start;

  & .ant-table-column-title {
    flex: none;
  }
}

.ant-table-title {
  padding: 0 !important;
}
