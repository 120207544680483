@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.modal {
  min-width: 1000px;

  @include _1024 {
    max-width: 700px;
  }

  @include _768 {
    max-width: 400px;
  }
}

.title {
  color: #232631;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
}

.top {
  border-bottom: 1px solid #0000001a;
  margin: 15px 15px 0;
}

.question {
  font-weight: 700;
  font-size: 16px;
  color: #232631;
  margin-bottom: 10px;
}

.commentsNum {
  color: #536471;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.scroll {
  max-height: 500px;
  overflow-y: auto;
}

.body {
  padding: 15px;
}

.emptyBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 17px;
  font-weight: 600;
  padding: 20px;
  color: #212229;
}
