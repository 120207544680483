@import 'sources/styles/variables.module.scss';

.bottomFunctionalButtons {
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  border-top: 1px solid #ced5db;
  padding-top: 16px;
  padding-bottom: 15px;
  background-color: $bgColor !important;
  margin-inline-start: 85px;
  padding-right: 15px;
  padding-left: 15px;
  & > div {
    margin: 0 auto;
    max-width: 1050px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
