.topPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.title {
  color: #232631;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0;
  cursor: pointer;
}

.search {
  min-width: 255px !important;
  font-size: 14px !important;
}
