.optionItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.optionInput {
  padding: 8px 16px !important;
  width: 452px !important;

  ::placeholder {
    color: #999;
  }

  &.error {
    border: 1px solid red;
  }
}

.selectButton {
  color: #e2e5e9;
  width: 16px;
  height: 16px;
  border: 2px solid #e2e5e9;
  margin-bottom: 20px;
}

.isWatchMode {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  & > div:last-child {
    padding-bottom: 3px;
    margin-bottom: 0;
  }
}

.checkBox {
  border-radius: 5px;
}

.radio {
  border-radius: 50%;
}

.selectWatchingMode {
  margin-bottom: 0 !important;
}
