@import 'sources/styles/variables.module.scss';

.buttons {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.traineesBlock {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.traineesBlockInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  margin-top: 24px;
  & > div {
    font-weight: 600;
    font-size: 14px;
    color: #232631;
    line-height: 21px;
  }
}

.tag {
  background-color: rgba(105, 65, 198, 0.13) !important;
  border: 1px solid #d1c2f3 !important;
  border-radius: 15px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 1px 8px !important;
}

.others {
  color: $brandColor;
  text-decoration: underline;
}

.avatarGroup {
  display: flex;
  align-items: center;
  gap: 8px;
}

.inviteTrainees {
  font-weight: 600;
  font-size: 18px;
  color: #232631;
  line-height: 21px;
}

.header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.checkbox {
  & span {
    font-weight: 400 !important;
    color: #2326319a !important;
    font-size: 13px !important;
  }
}

.traineesLine {
  cursor: pointer;
}

.profiles {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
