.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 0;
  }
  button {
    font-size: 16px;
  }
}
