.loader {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  border: none !important;
  & img {
    width: 40px !important;
    height: 40px !important;
  }
}
