@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.inputsContainer {
  height: 50px;
  margin-top: 15px;
  margin-bottom: 5px;
  direction: ltr;
  display: flex;
  margin-inline-start: -4px;
  margin-inline-end: -4px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    text-align: center;
    padding: 0 !important;
    box-shadow: none !important;

    &:focus {
      outline: 1px solid rgb(206, 206, 206) !important;
    }
  }
}

.submitBtn {
  margin-bottom: 25px;
  margin-top: 25px;
  background-color: $brandColor;
}

.errorMsg {
  span {
    color: #ff4d4f;
  }
}

.info {
  color: #fff;

  button {
    padding: 0;
  }

  .time {
    color: #fff !important;

    &.dark {
      color: $black !important;
    }
  }
}

.receiveCode {
  color: #fff;
  cursor: pointer;
  &.dark {
    color: $black;
  }
}
