@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.leastPerformers {
  background-color: #fff;
  border-radius: 15px;
  padding: 32px;
  width: 100%;
  border: 1px solid $borderColor;

  @include _680 {
    padding: 10px;
  }
}

.blockTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blockTitleText {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

.blockTitleSubText {
  margin-top: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
}

.seeAll {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-decoration: underline;
  color: #004d98;
  cursor: pointer;
}

.table {
  margin-top: 10px;
  th {
    background-color: #fff !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #8c8c8c !important;
    padding-bottom: 8px !important;

    &::before {
      all: unset !important;
    }
    &:first-child {
      padding-inline-start: 0 !important;
    }

    &:last-child {
      text-align: right;
      padding-inline-end: 5px !important;
    }
  }

  tr {
    td {
      &:first-child {
        padding: 12px !important;
        padding-inline-start: 0 !important;
      }
      &:last-child {
        text-align: right;
        padding: 12px !important;
        padding-inline-end: 5px !important;
      }
    }
  }
}

.nameCol {
  display: flex;
  gap: 10px;
  align-items: center;
}
