@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';

.program {
  margin: 0 auto;
  .loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.confirmLoad {
  margin-top: 10px;
  & > div:nth-child(2) > div > div > div:last-child {
    direction: ltr;
    button:last-child {
      margin-inline-start: 0;
      margin-inline-start: 8px;
    }
  }
}

.addTemplate {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}

.container {
  border: none !important;
  width: 741px !important;
  margin: 0 auto;
  border: 1px solid $borderColor !important;
  border-radius: 10px !important;
  padding: 32px !important;
  padding-bottom: 16px !important;
  background-color: transparent !important;
  margin-bottom: 80px !important;
  overflow: hidden;

  & > div {
    padding: 0 !important;
  }
}

.whiteBg {
  background-color: #fff !important;
}

.dropStyles {
  padding: 0 !important;
}

.link {
  color: $brandColor;
  font-size: 16px;
  font-weight: 500;

  span {
    margin-inline-start: 5px;
  }
}

.block {
  background-color: #f7f7f7;
  padding: 20px;
  margin-top: 20px;
  border-radius: 12px;

  @include _400 {
    padding: 5px;
    margin-top: 5px;
  }
}

.title {
  color: #000;
}

.btn {
  margin-top: 20px !important;
  background-color: $brandColor !important;
  font-size: 13px !important;
  border-radius: 6px !important;
  height: 40px !important;

  &:disabled {
    color: #fff !important;
    opacity: 0.7;
  }
}

.blockWrapper {
  &:nth-child(5) {
    margin-top: 10px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  @include programCreationButton;
  color: #fff !important;
}

.deleteBtn {
  background-color: #ff4d4f !important;

  &:hover {
    background-color: #ff7875 !important;
  }
}

.buttonDelete {
  font-size: 13px !important;
  border-radius: 6px !important;
  height: 40px !important;
}

.controls {
  display: flex;
  justify-content: flex-end;
}

.controlsWithTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h5 {
    margin-bottom: 0;
  }
}

.templateSelected {
  padding: 0 !important;
}
