@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.fullNameCell {
  display: flex;
  gap: 12px;
  align-items: center;

  @include _768 {
    flex-direction: column;
    font-size: 20px !important;
    gap: 0px;
  }
}

.row {
  cursor: pointer;
}
