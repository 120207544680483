.avatar {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarPic {
  border-radius: 50%;
  width: 40px;
}
.userInitials {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #000 !important;
}

.userLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(255, 191, 0);
}
