@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.navigationBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 85px;
  min-height: 70px;
  transition: background-color 0.2s ease;
  font-size: 12px;
  color: #c2c0c0;
  text-align: center;
  margin-bottom: 15px;

  &:hover {
    background-color: #efefef;
    color: #c2c0c0;
  }

  @include h_900 {
    min-height: 60px;
    margin-bottom: 0;
  }

  @include _480 {
    height: 70px;
    width: 50px;
  }
}

.routeText {
  font-size: 12px;
  text-align: center;

  @include h_900 {
    font-size: 10px;
  }

  @include _480 {
    display: none;
  }
}

.activeRoute {
  color: $brandColor !important;
  border-inline-end: 2px solid $brandColor !important;
}
