.menu {
  border-radius: 10px !important;
  padding: 0 !important;
  overflow: hidden;

  & li {
    font-family: 'DM Sans', 'Noto Sans', sans-serif !important;
    font-weight: 400 !important;
    padding: 10px !important;
  }
}
