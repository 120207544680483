.btns {
  display: flex;
  gap: 10px;
  align-items: center;
}

.container {
  & .btn {
    height: 42px !important;
    max-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
