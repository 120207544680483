.container {
  margin: 25px 40px 0;
}
.headerContainer {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  gap: 30px;
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
}
