.copyInput {
  input {
    padding: 8px 10px !important;
  }
}

.buttonRight {
  & > div > div {
    width: 100%;
  }
  & > div > div {
    display: flex;
    flex-direction: row-reverse;
  }
}
