@import 'sources/styles/variables.module.scss';

.panel {
  padding: 0 !important;

  & > div:nth-child(2) {
    & > div {
      padding: 0 12px !important;
    }
  }
}

.checkCircle {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showResultButton {
  width: 120px !important;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.programName {
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.noQuestionsMessage {
  text-align: center;
  padding: 20px;
}
