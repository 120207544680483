.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #232631;
  margin-bottom: 12px;
}

.select {
  border-radius: 5px !important;
  min-width: 300px !important;
  height: auto !important;
  & > div {
    border-radius: 5px !important;
    border-color: #e2e5e9 !important;
    min-height: 40px !important;
    height: auto !important;
    max-height: auto !important;
    display: flex !important;
    align-items: center !important;
    padding: 1px 4px !important;

    & > span {
      display: flex !important;
      align-items: center !important;
    }
  }
}
