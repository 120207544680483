.actionBtns{
  display: flex;
  align-items: center;
  gap: 20px;
}

.action{
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px !important;
  padding: 8px;
  border: 0 !important;
  background-color: transparent !important;
  gap: 5px;

}