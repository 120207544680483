.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #232631;
  margin-bottom: 20px;
}

.container {
  margin-top: 20px;
}
