$defaultFont: Verdana, sans-serif;
$defaultFontSize: 14px;

:root {
  --brandColor: #0079cb;
  --secondaryColor: #ecf0f3;
  --bgColor: #ecf0f3;
}

$brandColor: var(--brandColor);
$secondaryColor: var(--secondaryColor);
$bgColor: var(--bgColor);
$borderColor: #e1e1e1;
$black: #232631;

:export {
  brandColor: $brandColor;
}
