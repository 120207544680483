.statusContainer {
  display: flex;
}

.status {
  padding: 2px 12px;
  border-radius: 50px;
  font-size: 12px;
  flex-grow: 0;
  flex-shrink: 1;
}

.delivered {
  background-color: #eef4ff;
  color: #3436c7;
  border: 1px solid #8ab2fc;
}

.seen {
  border: 1px solid #92dbb0;
  color: #027948;
  background: #ecfdf3;
}

.sent {
  color: #6941c6;
  border: 1px solid #caa7fe;
  background: #f9f5ff;
}

.error {
  color: #da1717;
  border: 1px solid #ff8888;
  background: #ffd9d9;
}
