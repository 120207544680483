.dayItem {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  list-style: none;

  .dropContainer {
    position: relative;

    .dragStart {
      transition: 0.3s all !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 99;
      background-color: #eee !important;
      right: 0;
      bottom: 0;
    }

    .dropMarker {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.3s all !important;
    }
  }
}

// Image tool styles
.imageType {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;

  .icon {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    svg {
      font-size: 20px;
    }
    div {
      display: flex;
      gap: 5px;
    }
  }
  .fileContainer {
    display: flex;
    gap: 10px;
    margin: 5px;
    margin-top: 15px;
    margin-inline-start: 20px;

    img {
      max-width: 150px;
      max-height: 150px;
    }
  }

  .text {
    margin: 15px;
  }
}

.questionsData {
  .header {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .questionsDataContent {
    text-align: center;
    margin-bottom: 25px;
    span {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.chart {
  width: 70%;
  height: 300px;
  margin: 0 auto;
}
.header {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.questionContent {
  margin-inline-start: 10px;
}

.dayDelete {
  // background-color: #fff;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  font-size: 24px;
  padding: 0 5px 0 0;
}

.dayDeleteRtl {
  left: 0 !important;
  right: unset;
}

.dropdownMenu {
  padding: 0;
  & > li {
    padding: 2px 2px;
  }
}

.changeDate {
  cursor: pointer;
  color: #1890ff;
}

.dayNumber {
  color: #232631 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 130% !important;
  margin-bottom: 16px !important;
}

.empty {
  text-align: center;
  margin: 40px 20px;
}
