.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  color: #232631;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
