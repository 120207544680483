@import 'sources/styles/variables.module.scss';

.container {
  padding: 10px 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 0;
  }
}

.emoji {
  font-size: 35px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: default;
}

.info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text {
  font-size: 16px;
  font-weight: 600;
}

.completionAt {
  color: #536471;
  font-size: 12px;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}
