.question {
  cursor: default;
  position: relative;
  border: 1px solid #dcdcdc;
  padding: 16px;
  border-radius: 10px;
  &:hover {
    .deleteIcon {
      opacity: 1;
    }
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.answerType {
  display: flex;
  flex-direction: column;
}
