@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';
@import 'sources/responsive.scss';

.dashboardGrid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 24px 48px;

  @include _1024 {
    grid-template-columns: 1fr;
  }

  @include _680 {
    grid-gap: 10px 48px;
    margin-bottom: 10px;
  }
}

.loadingContainer {
  @include loadingContainer;
}
