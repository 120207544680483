.container {
  margin-top: 20px;
}

.progress {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.icon {
  font-size: 18px;
}
