.question {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
  cursor: pointer;
}

.name {
  color: #232631;
  font-weight: 600;
  font-size: 14px;
}
