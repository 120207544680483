@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';

.controlsWithTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h5 {
    margin-bottom: 0 !important;
  }

  @include _979 {
    flex-direction: column;
    gap: 10px;
  }
}

.mainSubcontrols {
  @include _600 {
    flex-direction: column;
    width: 100%;
    & div {
      width: 100% !important;
      margin: 0 !important;
      & > span {
        width: 100% !important;
        max-width: none;
      }
    }
  }
}

.block {
  padding: 20px 0;
  border-radius: 12px;

  @include _400 {
    padding: 5px;
    margin-top: 5px;
  }
}

.title {
  color: #000;
  margin-bottom: 0 !important;
}

.btn {
  @include programCreationButton;
  color: #fff !important;

  border: 1px solid #232631 !important;
  padding: 2px 6px !important;
  background-color: transparent !important;
  color: #232631 !important;
  font-size: 12px !important;

  height: auto !important;
  margin-inline-end: 10px !important;

  @include _600 {
    margin-inline-end: 0px !important;

    & div {
      width: 100% !important;
    }
  }
}

.uploadContainer {
  padding: 0 !important;
  margin: 0 !important;

  @include _600 {
    & button,
    > div {
      width: 100% !important;
    }
  }
}

.controls {
  display: flex;
  justify-content: flex-end;

  @include _600 {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    width: 100%;
  }
}

.tab {
  font-size: 13px;
  font-weight: 500;
  color: #6a6a6a;
  display: flex;
  flex: 1;
  gap: 3px !important;

  &.active {
    color: $brandColor;

    & .label {
      background-color: $brandColor;
    }
  }
}

.label {
  background-color: #c9deff;
  border-radius: 50px;
  color: #6a6a6a;
  padding: 1px 3px;
  font-size: 12px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter {
  margin-bottom: 10px;
}
