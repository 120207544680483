@import 'sources/styles/styles.scss';
@import 'sources/responsive.scss';

.header {
  margin-top: 25px;
}

.title {
  @include listTitle;
}

.container {
  padding-bottom: 40px;
}
