.container {
  padding: 8px 12px;
  background-color: #6194f466;
  border: 1px solid rgba(97, 148, 244, 0.4);
  border-radius: 6px;
  color: #232631;
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
}
