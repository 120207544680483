@import 'sources/styles/variables.module.scss';

.bottomFunctionalButtons {
  bottom: 0;
  right: 0;
  left: 0;
  padding-inline-start: 85px;
  padding-inline-end: 20px;
  position: fixed;
  border-top: 1px solid #ced5db;
  padding-top: 16px;
  padding-bottom: 15px;
  background-color: $bgColor !important;

  & > div {
    padding: 0 110px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
