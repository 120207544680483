@import 'sources/responsive.scss';
@import 'sources/styles/styles.scss';
@import 'sources/styles/variables.module.scss';

.actionPanel {
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 5px;
}

.sendBtn {
  background-color: $brandColor !important;
  border: 0 !important;
  color: #fff !important;
  padding: 12px 25px !important;
  border-radius: 6px !important;
  height: auto !important;
  min-height: 48px;

  &.disabledBtn {
    opacity: 0.7;
  }
}

.items {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 15px;
}

.optionAvatar {
  width: 40px;
  border-radius: 50px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.emptyTextContainer {
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  gap: 20px;
}

.emptyText {
  font-size: 16px;
  color: #6a6a6a;
}

.emptyTextImg {
  width: 280px;
}

.approval {
  display: flex;
  align-items: center;
  gap: 10px;
  border-spacing: initial !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox {
  font-weight: 500;
}

.footerOptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.invalidPhoneInput {
  & input {
    color: rgb(172, 0, 0) !important;
  }
}

.inputContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.autoComplete {
  flex: 1;
}

.phoneInput {
  direction: ltr;
  flex: 1;

  input {
    direction: ltr;
    padding: 4px 10px !important;
    border: 1px solid #d9d9d9;
    transition: border-color 0.2s, box-shadow 0.2s;
    padding: 0 10px;
    border-radius: 5px !important;
    padding-bottom: 2px;
    height: 48px !important;

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
      transition: border-color 0.3s, box-shadow 0.3s;
    }
  }
}
