$button-padding: 10px 12px;
$button-font-weight: 500;
$button-font-size: 12px;
$button-line-height: 130%;
$button-border-radius: 5px;
$button-gap: 5px;
$button-margin-end: 12px;
$button-border: 0;

$button-sent-bg: #e5f2fa;
$button-not-send-bg: rgb(222, 211, 211);
$button-default-bg: red;

@mixin button-styles($bg-color) {
  cursor: pointer;
  background-color: $bg-color;
  margin-inline-end: $button-margin-end;
  padding: $button-padding;
  border-radius: $button-border-radius;
  font-weight: $button-font-weight;
  font-size: $button-font-size;
  line-height: $button-line-height;
  display: flex;
  align-items: center;
  gap: $button-gap;
  border: $button-border;
}

.buttonSent {
  @include button-styles($button-sent-bg);
}
.buttonRead {
  @include button-styles($button-sent-bg);
}

.buttonNotSend {
  @include button-styles($button-not-send-bg);
}

.buttonDefault {
  @include button-styles($button-default-bg);
}
