.customDropdown {
  ul {
    border-radius: 10px !important;
    & > li {
      min-width: 140px !important;
      padding-top: 10px;
      padding-bottom: 10px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.iconContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
