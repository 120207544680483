.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding-bottom: 10px;
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
