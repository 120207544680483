.item {
  margin-bottom: 10px !important;

  & > div {
    display: flex !important;
    flex-direction: column !important;

    & > div {
      & > label {
        margin-bottom: 0 !important;
        display: flex !important;
        font-weight: 400 !important;
        color: #6a6a6a !important;
        font-size: 13px !important;
      }
    }
  }
}
