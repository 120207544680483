.uploadIcon {
  font-size: 20px;
  color: #232631;
}

.textContainer {
  font-size: 12px;
  color: #232631;
}

.link {
  color: #164997;
  text-decoration: underline;
  margin-bottom: 10px;
  &:hover {
    color: #718fbd;
    text-decoration: underline;
    margin-bottom: 10px;
  }
}

.info {
  color: #536471;
}

.loadingInfo {
  font-size: 12px;
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fileName {
  font-weight: 600;
}

.cancelBtn {
  border: 0 !important;
  background-color: transparent !important;
  color: #164997 !important;
  box-shadow: none !important;

  & span {
    text-decoration: underline;
  }
}
