@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  background-color: $bgColor !important;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include _600 {
      padding: 0 20px;
    }
  }

  .container {
    flex: 1;
    min-height: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    transition: 0.3s all ease;
    background-color: $bgColor !important;
    .content {
      min-height: auto;
      padding: 15px;
      padding-inline-start: 30px;
      background-color: $bgColor !important;
      flex: 1;
      display: flex;
      flex-direction: column;

      @include _480 {
        padding: 12px;
      }

      @include _400 {
        padding: 5px;
      }
    }
  }
}

.dropdown {
  & > button:last-child {
    border-color: #001529;
    background-color: #001529;

    & > span {
      color: #fff;
      font-size: 20px;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  & > span:first-child {
    @include _850 {
      display: none;
    }
  }
}

.sidebarIcon {
  font-size: 20px !important;
  margin-inline-end: 10px;
}

.sidebarMargin {
  margin-inline-start: 70px;
  transition: 0.3s all ease;

  @include _480 {
    margin-inline-start: 50px;
  }
}

.actionButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @include _850 {
    justify-content: space-between;
    padding: 0 20px;
  }
}

.responsiveMenu {
  display: none;
  svg {
    color: #fff;
    font-size: 24px;
  }
  @include _850 {
    display: block;
  }
}

.notifications {
  margin-bottom: 5px;
}

.menu {
  margin-inline-start: 5px !important;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(178, 178, 178);
  }

  cursor: default !important;
  & > li {
    cursor: default !important;
  }
}
