@import 'sources/responsive.scss';

.inline {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 24px;
  margin-bottom: 24px;
  & > div {
    height: 151px !important;
  }
}

.formItem {
  width: 100% !important;

  label {
    color: #6a6a6a !important;
    font-size: 13px !important;
    line-height: 16px !important;

    &::before {
      display: none !important;
    }
  }
  input {
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 10px;
  }

  @include _400 {
    margin-bottom: 10px;
  }
}

.formInput {
  height: 40px !important;
}

div[role='alert'] {
  font-size: 13px;
}

.datails {
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;

  @include _850 {
    width: 100%;
  }

  @include _480 {
    padding-top: 5px;
  }

  .imagesContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include _480 {
      grid-template-columns: 1fr;
    }
  }
}

.uniqueId {
  width: 100% !important;
}

.textArea {
  border-radius: 10px !important;
}

.publicId {
  border: 1px solid #dcdcdc !important;
  border-radius: 8px !important;
  padding: 10px !important;
}
