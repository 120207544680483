@import 'sources/styles/variables.module.scss';

.wrapper {
  z-index: 1000;
  bottom: 0;
  right: 14px;
  left: 100px;
  position: fixed;
  border-top: 1px solid #ced5db;
  padding-top: 16px;
  padding-bottom: 15px;
  background-color: $bgColor !important;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  max-width: 1095px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rtl {
  right: 100px !important;
  left: 14px !important;
}

.toTopBtn {
  transform: translateY(500px);
  transition: all ease 0.2s;

  &.visible {
    transform: translateY(0);
  }
}
