@import 'sources/styles/variables.module.scss';

.btn {
  margin-top: 20px !important;
}

.autocompleteInput {
  height: 48px !important;
  & > div {
    border-radius: 8px !important;
    height: 48px !important;
    padding-left: 12px !important;

    & input {
      height: 45px !important;
    }

    & span:nth-child(2) {
      margin-top: 3px;
    }
  }
}

.switchContainer {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  font-weight: 600;
  color: #232631;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: #232631;
}

.description {
  height: 100px !important;
  resize: none !important;
  border-radius: 8px !important;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.titleLabel {
  padding: 0px 4px;
  font-size: 12px;
  border-radius: 20px;

  &.enable {
    border: 1px solid #93dfd1;
    background-color: #cafbf2;
    color: #188d78;
  }

  &.disable {
    border: 1px solid #fed6a7;
    color: #e76c38;
    background-color: #fff9f5;
  }
}

.createBtn {
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
  transition: all ease 0.2s;

  border-bottom: 1px solid #ebebeb;

  &:hover {
    background-color: #f8f8f8;
  }
}
