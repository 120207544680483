@import 'sources/styles/styles.scss';
@import 'sources/responsive.scss';

.card {
  margin-inline-start: 110px !important;
  margin-inline-end: 110px !important;
  position: relative;
  border: 0 !important;
  background-color: transparent !important;
  max-width: 400px;
  width: 100%;

  @include _768 {
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
  }

  @include _400 {
    max-width: 320px;
  }

  .title {
    color: #fff;
    margin-bottom: 15px !important;
  }

  .form {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: -24px;
    padding: 24px;
    padding-bottom: 5px;

    .alert {
      margin-bottom: 25px;
    }
  }

  .formItem {
    label {
      color: #fff !important;
    }

    input {
      border-color: #d9d9d9 !important;
    }
  }

  .btn {
    background-color: $brandColor;
    margin-top: 20px;
  }
}
