.icon {
  width: 44px;
  height: 44px;
  background-color: #ededed;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attendanceInfoItem {
  flex: 1;
  display: flex;

  .itemInfo {
    margin-inline-start: 13px;
    display: block;

    & > div:first-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    & > div:last-child {
      margin-top: 4px;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
    }
  }
}
