.block {
  background-color: #fff;
  width: 100%;
  padding: 24px;
  border-radius: 10px;

  &:first-child {
    margin-top: 30px;
  }
  margin-top: 10px;

  .blockTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .blockContent {
    margin-top: 26px;
    display: flex;
    align-items: center;
    gap: 16px;

    .blockContentText {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
    }
  }
}

.contentHeader {
  display: grid;
  grid-template-rows: 1fr 0.8fr;
}
