@import 'sources/styles/variables.module.scss';

.pickerContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
  position: relative;

  & > div {
    width: 100%;
  }
}

.textArea {
  min-height: 100px !important;
  max-height: 200px !important;
  border-radius: 10px !important;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
  margin-bottom: 8px;
}

.picker {
  border-radius: 10px !important;
  height: 48px !important;
  width: 100%;
}

.footerButtons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.footerOneBtn {
  justify-content: flex-start;
  margin-top: 40px;
}

.midLine {
  margin-bottom: 15px;

  gap: 20px;

  & > div {
    width: 100%;
  }
}

.scheduleSelect {
  width: 100%;
}

.dropdownBtn {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $brandColor;
}

.menu {
  max-height: 300px;
  overflow-y: auto;
}

.select {
  margin-bottom: 15px !important;
  border-radius: 10px !important;
  & > div {
    border-radius: 10px !important;
    border-color: #e2e5e9 !important;
    min-height: 48px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    display: flex !important;
    align-items: center !important;
    & > span {
      display: flex !important;
      align-items: center !important;
    }
  }

  & > span {
    top: 60% !important;
  }
}

.autocompleteSelect {
  height: 48px !important;
  & > div {
    border-radius: 8px !important;
    height: 48px !important;
    padding-left: 12px !important;
    display: flex !important;
    align-items: center !important;

    & input {
      height: 45px !important;
    }

    & span:last-child {
      // margin-top: 3px;
      display: flex !important;
      align-items: center !important;
    }
  }
}

.input {
  margin-bottom: 0 !important;
}

.textbox {
  position: relative;
}

.vars {
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 10;
}

.varsRtl {
  right: 0;
  left: auto;
}

.nowLabelContainer {
  position: absolute;
  top: -15px;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nowLabel {
  padding: 10px;
  cursor: pointer;
  color: $brandColor;
  transition: all ease 0.2s;

  &:active {
    opacity: 0.6;
  }
}

.programSelect {
  margin-bottom: 10px;
  position: relative;
}

.link {
  position: absolute;
  top: -15px;
  z-index: 1;
  cursor: pointer;
  padding: 10px;
  cursor: pointer;
  color: $brandColor;
  transition: all ease 0.2s;

  &:active {
    opacity: 0.6;
  }
}

.linkLtr {
  right: 0;
}

.linkRtl {
  left: 0;
}
