.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.line {
  display: flex;
  align-items: center;
}

.day{
  font-size: 18px;
  font-weight: 700;
  color: #232631;
  margin-inline-end: 30px;
}

.diviner{
  width: 4px;
  height: 4px;
  background-color: #8C8C8C;
  border-radius: 4px;
}

.details{
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.detailsItem{
  font-size: 13px;
  font-weight: 500;
  color: #232631;
}
