@import 'src/sources/styles/variables.module.scss';

.menu {
  width: 348px !important;
  min-width: 300px !important;
  position: relative;
  z-index: 10000;
}

.radiosContainer {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.radio {
  padding: 10px;
}

.title {
  font-size: 15px;
  font-weight: 600;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.reset {
  color: $brandColor;
  cursor: pointer;
}

.statusHeaderCol {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
