@import 'sources/styles/variables.module.scss';
@import 'sources/responsive.scss';

.mainTitle {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
}

.title {
  font-weight: 600;
}

.item {
  margin-bottom: 15px;
}

.btnContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
