.textArea {
  min-height: 100px !important;
  max-height: 200px !important;
  border-radius: 10px !important;
}

.container {
  margin: 25px 40px 0;
}
.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
}
.back {
  margin-bottom: 15px;
}
