@import 'sources/responsive.scss';

.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.panel {
  background-color: #fff;
  margin-bottom: 10px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 30px;
}

.subheader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 30px;
  flex: 1;
}

.searchInput {
  max-width: 300px;
}

.nameCol {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  cursor: pointer;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  cursor: pointer;
  color: #80838c;
  font-size: 13px;
  font-weight: 500;
}

.arrow {
  transition: all ease 0.2s;

  &.active {
    transform: rotate(180deg);
  }
}

.row {
  cursor: pointer;
}

.statistic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.programSelect {
  min-width: 200px !important;
  & > div {
    border-radius: 8px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.exportBtn {
  padding: 8px 15px !important;
}
