.container {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;

  .content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 4rem 0.5rem 1rem;
    background: #f3f2f8;
    border-radius: 8px;
    border-top-left-radius: 0;
    position: relative;

    [dir='rtl'] & {
      padding: 0.5rem 1rem 0.5rem 4rem;
    }

    .time {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      font-size: 0.7rem;

      [dir='rtl'] & {
        right: initial;
        left: 0.5rem;
      }
    }
  }
}
