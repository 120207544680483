.container {
  display: flex;
  gap: 8px;
  margin-bottom: 15px;
}

.username {
  color: #232631;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
}

.body {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  padding-right: 30px;
}

.details {
  width: 100%;
}

.text {
  color: #232631;
  font-size: 13px;
  margin-bottom: 3px;
}

.date {
  font-size: 12px;
  color: #6a6a6a;
}

.options {
  position: absolute;
  top: 10px;
  right: 10px;
}

.optionsRtl {
  right: auto;
  left: 10px;
}
