@import 'src/sources/styles/variables.module.scss';

.program {
  margin-bottom: 10px !important;
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  padding: 15px !important;
}

.selectProgramTitle {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.noPrograms {
  font-size: 13px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reset {
  color: $brandColor;
  cursor: pointer;
}
