.toolTitle {
  color: #232631;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.visibleAt {
  color: #536471;
  font-size: 12px;
  line-height: 16px;
}
