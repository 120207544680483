.actionButtons {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;

  button {
    width: 25px;
    height: 28px;
    padding: 2px;
    height: 'auto' !important;
  }
}

.menu {
  & ul {
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: hidden !important;

    & li {
      font-family: 'DM Sans', 'Noto Sans', sans-serif !important;
      font-weight: 400 !important;
      padding: 10px !important;
    }
  }
}
