@import 'sources/styles/variables.module.scss';

.container {
  display: flex;
  width: 100%;
  gap: 0.5rem;

  > div:last-child {
    flex: 1;
  }

  .input {
    border-radius: 50px !important;
    overflow: hidden;
    padding-bottom: 0;
    padding: 0 0.5rem 0 1rem;

    [dir='rtl'] & {
      padding: 0 1rem 0 0.5rem;
    }
  }

  .sendBtn:not(:disabled) {
    background: $brandColor;
    border-color: $brandColor;
  }
}
