@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.card {
  margin-inline-start: 110px !important;
  margin-inline-end: 110px !important;
  position: relative;
  border: 0 !important;
  background-color: transparent !important;
  max-width: 400px;
  width: 100%;

  @include _768 {
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
  }

  @include _400 {
    max-width: 320px;
  }

  .phoneInput {
    direction: ltr;

    input {
      direction: ltr;
      padding: 4px 5px !important;
      background-color: #fefefe19;
      border-radius: 5px;
      border: none;
      padding: 15px !important;
      color: #fff;

      &::placeholder {
        color: #adadad;
      }
    }
  }

  .title {
    color: #fff;
    margin-bottom: 15px !important;
  }

  .form {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: -24px;
    padding: 24px;
    padding-bottom: 5px;

    .alert {
      margin-bottom: 25px;
    }
  }

  .btn {
    background-color: $brandColor;
    margin-top: 20px;
    border: 0;
  }

  .titleDark {
    color: $black;
  }
}
