@import 'sources/styles/styles.scss';
@import 'sources/styles/variables.module.scss';

.templateMore {
  border-radius: 10px !important;
  & > li {
    min-width: 90px !important;
    padding-top: 7px;
    padding-bottom: 7px;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.templateItem {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  margin-bottom: 12px;
  border-radius: 15px;
  padding: 16px;
  align-items: center;
  border: 1px solid $borderColor;
}

.templateNameInfo {
  & > div:first-child {
    @include listItemTitle;
  }
  & > div:last-child {
    @include listItemSubTitle;
    font-weight: 500;
  }
}

.numberOfDays {
  & > div:first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  & > div:last-child {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
}

.more {
  display: flex;
  justify-content: flex-end;
}
