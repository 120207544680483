.toolTimePick {
  display: flex;
  margin-top: 10px;
  align-items: flex-end;
  gap: 20px;
}

.revealedText {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #232631;
  margin-bottom: 15px;
}

.timePicker {
  border-radius: 5px !important;
  border-color: #dcdcdc !important;
  & > div > input {
    &::placeholder {
      color: #232631;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.timePickerLabel {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #6a6a6a;
  margin-bottom: 8px;
}

.timePickerContainer {
  opacity: 0;
  transition: 0.2s all;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.show {
  opacity: 1;
}

.timePickersContainer {
  display: flex;
  gap: 20px;
}

.dateLine {
  display: flex;
  align-items: flex-end;
  gap: 30px;
}

.days {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-top: 20px;
  min-height: 33px;
}

.checkbox {
  margin-bottom: 5px;
}

.dayInput {
  border-radius: 5px !important;
}
