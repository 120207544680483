.styleClear {
  margin-bottom: 0 !important;
}

.input {
  width: 500px;
  padding: 8px 16px;
  padding-inline-end: 40px;
  position: relative;

  &.error {
    border-color: red;
  }
}

.inputWrapper {
  max-width: 500px !important;
}

.ToolWatchModeInner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -10px;
}
