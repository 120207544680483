@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';

.subtitle {
  font-size: 12px !important;
  color: #232631 !important;
  font-weight: 400 !important;
}

.nameCol {
  display: flex;
  align-items: center;
  gap: 8px;
}

.image {
  width: 32px;
  border-radius: 50px;
  height: 32px;
  object-fit: cover;
}

.text {
  color: #232631 !important;
  font-size: 13px !important;
  font-weight: 500 !important;

  @include _600 {
    font-size: 11px !important;
  }
}

.subtext {
  font-size: 12px;
  color: #6a6a6a;

  @include _600 {
    font-size: 10px !important;
  }
}

.approval {
  & span {
    font-size: 13px !important;
  }
}

.tableContainer {
  padding-inline-end: 5px;
  height: 550px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201);
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(181, 181, 181);
  }
}

.alert {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  & > div {
    width: 300px;
    border-radius: 10px;
    cursor: pointer;
  }
}
.delete {
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    color: red;
  }
}

.errorMessage {
  font-size: 12px;
  color: red;
}
