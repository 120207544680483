@import 'sources/responsive.scss';
@import 'sources/styles/styles.scss';

.onlyDesktop {
  display: none;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba($color: #fff, $alpha: 0.8);
  z-index: 10;

  .icon {
    font-size: 64px;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  @include _1024 {
    display: flex;
  }
}
