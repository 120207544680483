@import './variables.module.scss';

.customConfirm {
  & > div:nth-child(2) {
    border-radius: 10px !important;
  }
}

.okBtn {
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 13px 24px !important;
  height: auto !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  margin-left: 0 !important;
}

.cancelBtn {
  color: #232631 !important;
  border: 1px solid #232631 !important;
  border-radius: 5px !important;
  padding: 12px 24px !important;
  height: auto !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  background-color: transparent !important;
  margin-inline-end: 5px !important;
}
