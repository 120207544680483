@import 'sources/styles/styles.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.title {
  @include listTitle;
  margin-bottom: 0;
}
