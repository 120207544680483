@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.attendanceBlock {
  background-color: #fff;
  border-radius: 10px;
  height: 503px;
  padding: 20px 32px 10px;
  border: 1px solid $borderColor;

  @include _680 {
    padding: 10px 10px 0;
  }
}

.attendanceInfo {
  display: flex;
  border-bottom: 1px solid #e5e9ec;
  padding-bottom: 24px;

  @include _680 {
    padding-bottom: 10px;
  }
}

.chartTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div:first-child {
    color: #232631;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  button {
    padding: 6px !important;
    height: 32px !important;
    span:first-child {
      padding-top: 3px !important;
    }
  }
}

.attendanceChart {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attendanceContainer {
  // 1/2 - all margins and paddings
  width: calc(100vw * 0.6666666 - 48px - 85px - 100px);
  // width: 100%;
  max-width: 100%;
  // min-width: 100%;
  overflow-x: scroll;
  height: 70%;
  padding-bottom: 20px;
  padding-top: 50px;
  position: relative;
  top: -20px;

  @include _1024 {
    width: calc(100vw - 48px - 85px - 130px);
  }

  @include _850 {
    width: calc(100vw - 48px - 40px - 130px);
  }

  @include _768 {
    width: calc(100vw - 48px - 15px - 130px);
  }

  @include _680 {
    width: calc(100vw - 20px - 130px);
    padding-top: 20px;
  }

  @include _480 {
    width: calc(100vw - 110px);
  }

  @include _400 {
    width: calc(100vw - 100px);
  }
}

.emptyChart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  color: #232631;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  opacity: 0.7;
}
