.profile {
  margin: 60px 110px;
}

.headInfo {
  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 26px;
  }
}
