@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 2.5rem);
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
  }

  .title {
    margin-top: 15px;
    font-size: 26px;
    font-weight: 700;
  }

  .loading {
    display: block;
    margin: 0 auto;
  }
}
