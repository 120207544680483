.container {
  max-width: 1090px;
  width: 100%;
  margin: 0 auto;
}

.fullScreenContainer {
  margin: 25px 40px 0;
}

.topOffset {
  margin-top: 25px;
}
