@import 'sources/responsive.scss';

.container {
  position: relative;
  z-index: 1;
  background: rgba($color: #fff, $alpha: 0.9);
  border-radius: 1rem;
  padding: 1.5rem;
  margin: 2rem;
  width: calc(50vw - 4rem);
  min-height: calc(100vh - 4rem);
  overflow: auto;

  @include _768 {
    margin: 1rem;
    margin-top: 6rem;
    width: calc(100vw - 2rem);
    min-height: calc(100vh - 8rem);
  }
}
