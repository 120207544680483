.btn {
  border: 1px solid #bfbfbf;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 20px;
  cursor: pointer;
  color: #222222;
}
