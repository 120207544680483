.answerType {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -28px;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  flex: 1;
}

.dropdown {
  position: absolute;
  top: 16px;
}

.dropdownLtr {
  right: 16px;
}

.dropdownRtl {
  left: 16px;
}
