.customInput {
  background-color: transparent !important;
  border-radius: 6px !important;
  border-color: #bfbfbf !important;
  padding: 8px 8px !important;
  input {
    background-color: transparent !important;
    font-size: 14px !important;
    &::placeholder {
      color: #8c8c8c;
    }
  }
}
