.loader {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.videoLoader {
  border: 1px solid #16499726;
  width: 338px;
  height: 190px;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  & > div:first-child {
    margin-bottom: 12px;
  }
  & > div:nth-child(2) {
    margin-bottom: 4px;
  }
  & > div:last-child {
    color: #232631;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}

.imageLoader {
  height: 190px;
  width: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  padding: 8px;
  border-radius: 2px;
  margin: 0 8px 8px 0;
  gap: 5px;
}

.filename {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 170px;
}

.dragger {
  & > span > div:first-child {
    background-color: #1649970d;
    border-radius: 10px;
    border: 1px dashed #16499726;
  }
  & > span > div:last-child {
    margin-top: 10px;
    & > div {
      width: 190px;
      height: 190px !important;
      & > div > div {
        border-radius: 10px !important;
      }
    }
  }
}

.hidden {
  & > span > div {
    border: none !important;
    & > span {
      padding: 0 !important;
      border: none !important;
    }
  }
}

.video {
  & > span > div:last-child > div {
    width: 366px !important;
    & > div {
      width: 366px !important;
    }
  }
}

.defaultFiles {
  & > span > div:last-child {
    display: flex;
    flex-direction: column !important;
    gap: 4px;
    & > div {
      height: 80px !important;
    }
  }
}
