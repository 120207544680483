@import 'sources/styles/variables.module.scss';

.linkContainer {
  display: grid;
  grid-template-columns: 350px 550px 50px;
  gap: 10px;
}

.input {
  height: 40px !important;
  :disabled {
    background-color: red !important;
  }
}

.linkPresent {
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-bottom: 10px;
}

.deleteLink {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
