@import 'sources/styles/styles.scss';

.btn {
  @include createNewButton;
}

.primary {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}
