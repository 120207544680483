.input {
  padding: 4px 8px;
  border: 0;
  outline: none;
  flex: 1;
  font-size: 13px;
  max-width: 140px;
}

.inputContainer{
  position: relative;
  border: 1px solid #DCDCDC;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.inputCheck{
  max-width: 30px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: green !important;
  display: flex !important;
  padding-right: 5px;
  padding-left: 5px;
}

.text{
  font-size: 13px;
  cursor: pointer;
  display: flex;
  gap: 5px;
}

.addText{
  color: #164997;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  gap: 5px;
}

.warn{
  width: 20px;
  height: 20px;
  display: flex !important;
  align-items: center !important;
  color: #D70C0C !important;
}
