@import 'sources/styles/variables.module.scss';

.form {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.title {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}

.formInputs {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  margin-bottom: 15px;
}

.formInputsUnion {
  margin-bottom: 15px;
}

.iconsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.icon {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    background-color: $bgColor;
  }

  &.selected {
    background-color: $brandColor;
    color: #fff;
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formItem {
  margin-bottom: 0 !important;
  flex: 1;

  & input {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.formItemEmoji {
  position: relative;
  width: 42px !important;
  margin-bottom: 0 !important;

  & input {
    cursor: pointer !important;
    caret-color: transparent !important;
  }
}

.emojiPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnContainer {
  margin-bottom: 0 !important;
}

.formNumLabel {
  color: #6a6a6a;
  font-size: 13px;
  margin-bottom: 5px;
}

.formNumConatainer {
  position: relative;
}

.formNum {
  display: grid;
  align-items: center;
  grid-template-columns: 140px 1fr;
  gap: 2px;
}

.completionAt {
  margin-bottom: 0 !important;
  & input {
    border-inline-end-color: transparent;
    border-start-end-radius: 0px !important;
    border-end-end-radius: 0px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.unit {
  margin-bottom: 0 !important;
  & input {
    border-inline-start-color: transparent;
    border-end-start-radius: 0px !important;
    border-start-start-radius: 0px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.border {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
  background-color: #fff;

  &.isRtl {
    right: 141px;
  }

  &.isLtr {
    left: 140px;
  }

  & > div {
    width: 1px;
    height: 28px;
    background-color: #dcdcdc;
  }
}
