.imageContainer {
  cursor: pointer;
  padding: 5px;
}

.image {
  width: 30px;
  height: 30px;
}

.menu {
  & li[aria-disabled='true'] {
    cursor: default !important;
    background-color: #d4ffc9 !important;
    & span {
      text-align: center !important;
      color: #333 !important;
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
}
