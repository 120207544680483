@import 'sources/responsive.scss';
@import 'sources/styles/styles.scss';

.loader {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableActions {
  width: 100%;
  justify-content: flex-end;
}

.responsiveList {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include _680 {
    grid-template-columns: 1fr;
  }
}

.cardActions {
  position: absolute;
  bottom: 0;
  right: 0;
}

.buttonRight {
  display: flex;
  justify-content: flex-end;
}

.paginationBlock {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  gap: 0 !important;
  grid-gap: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
