@import 'sources/styles/variables.module.scss';

.container {
  padding: 20px;
}

.question {
  font-size: 13px;
  color: #232631;
}

.answer {
  font-weight: 600;
}

.comments {
  color: $brandColor;
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  text-align: end;
}
