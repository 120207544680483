.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.message {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
