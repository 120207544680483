.maxMin {
  display: flex;
  gap: 16px;
}

label {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #232631;
  margin-bottom: 10px;
  display: block;
}

.numbers {
  width: 100px;
  padding: 8px 16px;

  &.error {
    border: 1px solid red;
  }
}
