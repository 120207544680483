@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.sidebarRlt {
  left: unset !important;
  right: 0 !important;
  border-right: 0;
  border-left: 1px solid $borderColor;
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 85px;
  z-index: 20;
  background-color: #fff;
  overflow: hidden;
  border-right: 1px solid $borderColor;

  @include _480 {
    width: 50px;
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
    margin-top: 32px;

    & .logoLoader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img,
    & .logoLoader {
      height: 38px;
      width: 38px;
      border-radius: 50%;

      @include _480 {
        height: 30px;
        width: 30px;
      }
    }
  }

  .routes {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    font-size: 16px;
    margin-top: 10px;
    gap: 0 !important;
  }

  .logoutBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 70px;
    margin-bottom: 0;
    background-color: #007acb49;
    border: 0;
    transition: background-color 0.2s ease;
    font-size: 12px;

    @include _480 {
      width: 50px;
    }

    > span:last-child {
      margin: 0 !important;
      @include _480 {
        display: none;
      }
    }

    &:hover {
      background-color: #007acb75;
    }

    &:focus,
    &:active {
      background-color: #007acb49;
    }
  }

  .languageSelect {
    @include _480 {
      display: none;
    }
  }
}

.isAdminOrCompany {
  cursor: unset;
}

.logout {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.textFort {
  font-size: 10px;
}

.userProfile {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.profileMenu {
  width: 80px;
  margin-top: 18px !important;
  & > li > div {
    // padding: 0 !important;
    padding: 0 calc(50% - 40px / 2) !important;
  }
  ul {
    border-radius: 10px !important;
    width: 170px;

    li {
      margin-top: 5px !important;
      margin-bottom: 0 !important;
      height: 35px !important;
    }
    li:last-child {
      border-top: 1px solid #e5e9ec;
      margin-bottom: 5px !important;
    }
  }
}

.userInitials {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}
