@import 'sources/styles/variables.module.scss';
@import 'sources/responsive.scss';

.programsSelect {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @include _680 {
    flex-direction: column;
    gap: 5px;
  }
}

.programs {
  width: auto !important;
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px;

  @include _680 {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    gap: 5px;
  }
}

.selectButton {
  border-color: #22222268 !important;
  background-color: transparent !important;
  border-radius: 20px !important;

  height: 38px !important;
  font-weight: 500;
  font-size: 15px;
  border-left-width: 1px !important;
  padding: 3px 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  &::before {
    all: unset !important;
  }
  &:not(:first-child) {
    max-width: 150px !important;

    @include _680 {
      max-width: 100% !important;
    }
  }
  & > span:last-child {
    display: block;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap;
  }

  @include _680 {
    width: 100% !important;
    font-size: 12px !important;
  }
}

.activeButton {
  background: $brandColor !important;
  color: #fff !important;
}

.selectMore {
  height: 38px !important;
  min-width: 120px !important;

  & > div {
    height: 38px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-color: #22222268 !important;
    border-radius: 20px !important;
    background-color: transparent !important;

    & > span:last-child {
      font-weight: 500 !important;
      font-size: 15px !important;
      color: #222222;
      display: flex;
      align-items: center;

      @include _680 {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding-right: 18px !important;
        padding-left: 18px !important;
        font-size: 12px !important;
      }
    }
  }

  &.selectMoreActive {
    & > span {
      color: #fff !important;
    }
    & > div {
      background: $brandColor !important;

      & > span:last-child {
        color: #fff !important;
      }
    }
  }

  @include _680 {
    width: 100% !important;
  }
}
