@import 'sources/styles/variables.module.scss';

.controls {
  border-top: 1px solid #0000001f;
  padding: 16px 24px;
}

.controlsInner {
  position: relative;
  display: flex;

  & > div:first-child {
    width: 100%;
  }
}

.avatar {
  position: absolute;
  top: 8px;
  left: 12px;
}

.btns {
  position: absolute;
  top: 10px;
  right: 8px;
}

.sendBtn {
  height: 28px;
  width: 28px;
  background-color: $brandColor;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  color: #fff;
}

.sendBtnPic {
  font-size: 20px;
  color: #fff;
}

.input {
  width: 100% !important;
  display: flex;
  flex: 1;
  padding: 0 56px !important;
  height: 48px !important;
}
