.itemGroup {
  & > div:not(:first-child) > div > div {
    display: flex;

    & > div:not(:first-child) > div > div > div {
      span {
        margin-inline-end: 4px;
      }
    }

    & > div {
      margin-inline-end: 20px;
      margin-bottom: 0;

      & > div > div > div {
        span {
          font-size: 14px;
          font-weight: 500;
          padding-inline-end: 14px;
        }
      }
    }
  }
}

.addQuestionText {
  color: #164997;
  text-decoration: underline;
  font-size: 14px;
  line-height: 130%;
  margin-top: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}
