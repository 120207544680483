.customTextarea {
  padding: 12px 10px;
  border-radius: 5px !important;
  padding-bottom: 2px;
}

.labeldefault {
}
.labelprimary {
}
.labelshadow {
}

.errorText {
  height: 20px;
  opacity: 0;
  color: #ff4d4f;
  transition: 0.3s all;
}
.show {
  opacity: 1;
  transition: 0.3s all;
}
