.modalCreateContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 4px;

  label {
    color: #6a6a6a !important;
    font-size: 13px !important;
    line-height: 16px !important;
    margin-bottom: 8px !important;
    display: block;
  }

  & > div:first-child {
    flex: 1;
  }
}

.modalInput {
  flex: 1 !important;
  padding: 12px 10px !important;
  border-radius: 10px !important;
  height: 48px !important;
}

.modalInputNumber {
  width: 100px !important;
  padding: 9px 5px !important;
  height: 48px;
  border-radius: 10px !important;
  & > div:first-child {
    &:hover {
      & > span {
        height: 50% !important;
      }
    }
    border-radius: 10px !important;
    opacity: 1 !important;
    span {
      border: none !important;
      color: #130f26 !important;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}
