@import 'sources/styles/styles.scss';

.btn {
  @include createNewButton;
}

.more {
  & ul {
    border-radius: 10px !important;
    overflow: hidden !important;
    padding: 0 !important;
    & > li {
      min-width: 90px !important;
      padding-top: 12px;
      padding-bottom: 12px;
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
      }
    }
  }
}
