@import 'sources/styles/variables.module.scss';

.steps {
  margin-top: 30px;
  display: flex;
  & > div {
    overflow-x: auto !important;
  }
}

.step {
  cursor: pointer;
  padding-inline-start: 0 !important;
  max-width: 150px !important;

  & > div {
    & span {
      font-size: 13px;
    }

    & > div:nth-child(2) {
      background-color: #d4d8db;
      margin-inline-end: 0 !important;
      color: #8b8b8b !important;
      width: 26px !important;
      height: 26px !important;
      line-height: unset !important;
    }

    & > div:last-child > div::after {
      background-color: #d4d8db !important;
      height: 2px !important;
      width: 10000px !important;
      left: 0 !important;
      right: 0 !important;
      top: 12px !important;
    }
  }

  &:hover {
    &:not(.currentStep) {
      &:not(.prevStep) {
        & > div > div {
          border-color: $brandColor !important;
          & > span {
            color: $brandColor !important;
          }
        }
      }
    }
  }
}

.prevStep {
  & > div > div:last-child > div::after {
    border-color: $brandColor !important;
    background-color: $brandColor !important;
  }

  & > div > div:nth-child(2) {
    background-color: $brandColor !important;
    border-color: $brandColor !important;
    margin-inline-end: 0 !important;
    color: #fff !important;
    width: 26px !important;
    height: 26px !important;
    line-height: unset !important;
    span {
      color: #fff !important;
    }
  }
}

.stepsScroll {
  width: 100%;
  height: 100%;
  max-width: 1095px;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    height: 7px !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: $bgColor;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201);
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(181, 181, 181);
  }
}

.skip {
  & > div > div:nth-child(2) {
    background-color: #d4d8db !important;
    border-color: #8b8b8b !important;
    & > span > span {
      color: #8b8b8b !important;
    }
  }
}

.rtlSkip {
  & > div > div:nth-child(2) {
    & > span > span {
      transform: rotate(180deg) !important;
    }
  }
}

.scrollButton {
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #c8c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}

.stepsContainer {
  display: flex;
}

.stepsContainerRtl {
  flex-direction: row-reverse;
}

.scroller {
  margin-bottom: 15px;
}

.scrollStep {
  max-width: 150px !important;
  min-width: 150px !important;
  width: 150px !important;

  &:first-child {
    padding-inline-start: 16px !important;
  }
}

.stepAddDay {
  padding-inline-end: 16px !important;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #8b8b8b !important;
    background-color: #d4d8db;
    border-radius: 50%;
    width: 26px !important;
    height: 26px !important;
    & > div:nth-child(2) {
      margin-right: 0 !important;

      & > span {
        font-size: 18px !important;
        line-height: unset !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 26px !important;
        height: 26px !important;
        & > svg {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }

    &:hover {
      border-color: $brandColor !important;
      & > div:nth-child(2) {
        background-color: $brandColor !important;
        & > span > span > svg {
          color: #fff !important;
        }
      }
    }
  }
}

.currentStep {
  & > div {
    & > div:nth-child(2) {
      background-color: $brandColor !important;
      border-color: $brandColor !important;
    }
  }
}

.stepSubtitle {
  position: relative;
  background-color: $bgColor;
  z-index: 100;
  padding: 0 5px;
  border: 1px solid #8b8b8b;
  border-radius: 5px;

  &.active {
    border: 1px solid $brandColor;
  }
}
