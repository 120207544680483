.container{
  position: relative;
}

.customSelect {
  height: 40px !important;
  border-radius: 5px !important;

  & > div {
    padding: 5px 16px !important;
    height: 40px !important;
    border-radius: 5px !important;
    border-color: #e2e5e9 !important;

    input {
      height: 40px !important;
    }

    & > span:last-child {
      height: 40px !important;
    }
  }

  &.error{
    & > div {
      border-color: red !important;
    }
  }
}

.label{
  color: #6A6A6A;
  font-size: 13px;
  margin-bottom: 5px;
}
