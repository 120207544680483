.container {
  background-color: #f4f4f4;

  & table {
    & tr {
      & td {
        border-bottom: 1px solid #d6d6d6 !important;
      }

      &:last-child {
        & td {
          border-bottom: none !important;
        }
      }
    }
  }
}
