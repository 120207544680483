.dayEditModalContent {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.datePicker {
  border-radius: 8px !important;
  padding: 8px 16px !important;
}

.btn {
  padding: 8px 16px !important;
  border-radius: 8px !important;
  height: auto !important;
}
