.container {
  position: relative;
  height: 190px !important;

  video {
    border-radius: 10px !important;
  }
}

.loadingContainer {
  a {
    color: #ededed;
  }
  span {
    color: #ededed;
  }

  &.cardLoading {
    opacity: 0.5;
  }
}

.cardItem {
  position: relative;
}

.loader {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.defaultFileCardItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  width: 530px;
  height: auto !important;
  transition: 0.3s all;
  padding-inline-end: 16px;

  & > div:first-child {
    display: flex;
    align-items: center;
  }
}

.deleteIconInFileItem {
  opacity: 0;
  transition: 0.3s all;
  background-color: #ececec;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.showDelete {
  &:hover {
    background-color: rgb(251, 251, 251);
    & > div:last-child {
      opacity: 1;
    }
  }
}

.fileIcon {
  background-color: #cfe7fe;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fileInfo {
  margin-inline-start: 12px;
  color: #232631;
  div:first-child {
    font-weight: 500;
    font-size: 14px;
  }
  div:last-child {
    font-weight: 400;
    font-size: 12px;
  }
}

.videoItem {
  display: flex;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgContentIdContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  background-color: #fff;
  z-index: 2;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px !important;
}
