@import 'sources/styles/variables.module.scss';

.wrapper {
  margin-bottom: 12px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid $borderColor;
}

.wrapperDraggableEffect {
  transition: 0.1s all;

  &:hover {
    border: 1px dashed transparent;
    cursor: grab;
    border-color: #1890ff;
  }
}

.wrapperUnbound {
  border: 1px solid #d2d2d2;
}

.footerButtons {
  display: flex;
  gap: 3px;
  margin-top: 24px;
}

.tags {
  margin-top: 20px;
}

.copyIdContainer {
  margin-bottom: 10px;
}
