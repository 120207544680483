@import 'sources/responsive.scss';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarImage {
  color: #fff !important;
  border-radius: 50% !important;
  object-fit: cover;
}

.hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all ease 0.4s;
  gap: 10px;
}

.editable {
  position: relative;

  &:hover {
    & .hover {
      opacity: 1;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  cursor: pointer;
  padding-inline-start: 20px;
}
