@import 'sources/styles/variables.module.scss';

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.titleInner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.templateName {
  margin-bottom: 0 !important;
  font-weight: 700 !important;
  font-size: 26px !important;
}

.backBtn {
  margin-bottom: 15px;
  & > span {
    color: $brandColor;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    cursor: pointer;
  }
}

.created {
  font-size: 13px;
  line-height: 130%;
  color: #536471;
}

.newTemplateBtnsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: -10px;
}

.id {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 5px;
  color: #536471;
}

.options {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.programName {
  font-size: 20px;
  font-weight: 600;
  word-break: break-all;
  flex: 1;
}

.bot {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}
