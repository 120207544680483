@import 'sources/styles/styles.scss';

.addTemplate {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.addTemplateTop {
  @extend .addTemplate;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
}

.templatePage {
  height: 100%;
  width: 100%;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.buttonRight {
  & > div > div {
    width: 100%;
  }
  & > div > div {
    display: flex;
    flex-direction: row-reverse;
  }
}

.paginationBlock {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  gap: 0 !important;
  grid-gap: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}

.listTitle {
  @include listTitle;
}

.newProgramButton {
  @include createNewButton;
}

.titleWithSearch {
  width: 600px;
  display: flex;
  align-items: center;
  gap: 32px;
}
