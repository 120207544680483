.customInput {
  padding: 0 10px;
  border-radius: 8px !important;
  padding-bottom: 2px;
  height: 38px !important;

  &.big {
    height: 48px !important;
  }

  &.withVariable {
    padding-right: 40px !important;

    &.rtl {
      padding-right: 10px !important;
      padding-left: 40px !important;
    }
  }
}

.labelshadow {
  color: #6a6a6a;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
}

.errorText {
  min-height: 20px;
  opacity: 0;
  color: #ff4d4f;
  transition: 0.3s all;
}

.show {
  opacity: 1;
  transition: 0.3s all;
}

.inputWrapper {
  position: relative;
}
