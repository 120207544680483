.userInitials {
  background-color: #ffbf00;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 500;
  font-size: 24px;
}

.avatarContainer {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &:hover {
    & > span:first-child {
      bottom: 0;
    }
  }
}

.upload {
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 20;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  height: 25px;
  cursor: pointer;
}

.uploadContent {
  width: 240px;
  text-align: center;
  color: #fff;
  span {
    display: block;
    margin-top: 5px;
    svg {
      width: 18px;
      height: 14px;
    }
  }
}

.uploadedImageContainer {
  img {
    width: 240px;
    height: 240px;
  }
}

.removeIcon {
  top: -5px;
  right: -5px;
  z-index: 100;
  position: absolute;
  cursor: pointer;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
}
