.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  border-radius: 50%;
  overflow: hidden;
}

.avatarImage {
  color: #fff !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
