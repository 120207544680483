.date {
  color: #6a6a6a;
  font-size: 13px;
}

.templateItem {
  background-color: #f8f8f8;
  border: 1px solid #dcdcdc;
  padding: 24px;
  border-radius: 6px;
  transition: border 0.2s ease;
  cursor: pointer;
  height: 67px !important;
  display: grid;
  grid-template-columns: 0.85fr 1fr auto;

  &:hover {
    border: 1px dashed #91acce;
    background-color: #eaf3ff;
  }
}

.templateItemSelected {
  border: 1px dashed #91acce;
  background-color: #cbe1ff;

  &:hover {
    border: 1px dashed #91acce;
    background-color: #cbe1ff;
  }
}
