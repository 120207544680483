.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-top: 5px;
}

.traineesSelected {
  color: #232631;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.checkbox {
  & span {
    font-weight: 400 !important;
  }
}

.notFound {
  margin-top: 10px;
  text-align: center;
  color: #333;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
