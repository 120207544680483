@import 'sources/responsive.scss';

.greetMessage {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #222222;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @include _1024 {
    flex-direction: column;
  }

  @include _680 {
    gap: 10px;
  }
}

.leastPerformersHeader {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  @include _1024 {
    justify-content: flex-start;
  }

  @include _680 {
    gap: 5px;
    flex-direction: column;
  }
}

.customInput {
  background-color: transparent !important;
  border-radius: 20px !important;
  border-color: #22222268 !important;
  min-width: 120px !important;

  input {
    background-color: transparent !important;
    border-color: #22222268 !important;

    &::placeholder {
      color: #232631;
    }

    @include _680 {
      font-size: 12px !important;
    }
  }

  @include _1024 {
    max-width: 250px;
  }

  @include _680 {
    max-width: 100%;
  }
}

.select {
  height: 38px !important;
  width: 150px !important;

  & > div {
    height: 38px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-color: #22222268 !important;
    border-radius: 20px !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span:last-child {
      font-weight: 500 !important;
      color: #222222;
      display: flex;
      align-items: center;
      font-size: 14px !important;

      @include _680 {
        font-size: 12px !important;
      }
    }
  }

  @include _680 {
    width: 100% !important;
  }
}
