@import 'sources/styles/variables.module.scss';

.customModal {
  & > div:nth-child(2) {
    & > button {
      direction: ltr !important;
      // border: 1px solid red !important;
      top: 2px;
      right: 10px;
      span > span {
        direction: ltr !important;
        font-size: 18px;
        color: #130f26;
      }
    }
    border-radius: 10px;
    & > div:nth-child(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
    }
    & > div:nth-child(2) {
      background-color: $bgColor;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
