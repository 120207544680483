.container {
  display: flex;
  gap: 5px;
}

.iconContainer {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
