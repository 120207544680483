@import 'sources/responsive.scss';
@import 'sources/styles/styles.scss';
@import 'sources/styles/variables.module.scss';

.program {
  display: grid;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 13px;
  padding: 16px 14px 14px 16px;
  grid-template-columns: auto 1fr;
  gap: 10px;
  position: relative;
  border: 1px solid $borderColor;
}

.body {
  display: flex;
  flex-direction: column;
}

.topLine {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;
}

.programsListTitle {
  @include listTitle;
}

.newProgramButton {
  @include createNewButton;
}

.programImage {
  width: 124px;
  margin: 0 auto;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  overflow: hidden;
}

.progressBlock {
  min-width: 200px;
  display: grid;
  grid-template-columns: 1fr 40px;
  text-align: start;
}

.programProgressBar {
  font-size: 13px;
  line-height: 1.2;
}

.progress {
  & div {
    height: 7px;
  }
}

.menuDropdown {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
}
.dropDown {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  align-items: flex-start;
}

.programMore {
  border-radius: 10px !important;
  overflow: hidden;
  & > li {
    padding-top: 7px;
    padding-bottom: 7px;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.bottomLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-inline-end: 20px;
}

.btn {
  padding: 8px 10px !important;
}

.programTitle {
  display: flex;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  & > span:first-child {
    @include listItemTitle;
  }
  .isPublic {
    margin-inline-start: 8px;
  }

  .isPublicNoMargin {
    margin-inline-start: 0px;
  }
}

.programGoal {
  @include listItemSubTitle;
}

.programMainInfo {
  display: flex;
  align-self: start;
}

.programInfoItem {
  margin-inline-end: 56px;
  padding-top: 20px;

  & > div:first-child {
    font-size: 14px;
    line-height: 16px;
  }
  & > div:last-child {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-top: 4px;
  }
}

.subtitle {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
}

.subtitleBig {
  font-size: 15px;
}

.btnOrPercentsContainer {
  align-self: end;
  padding-bottom: 10px;
}

.tag {
  border-radius: 10px;
  line-height: 1;
  padding: 3px 8px;
  font-size: 12px;

  &.private {
    border: 1px solid #b8e0ff;
    background-color: #eaf6ff;
    color: #166caf;
  }

  &.public {
    border: 1px solid #93dfd1;
    background-color: #cafbf2;
    color: #188d78;
  }
}
