@import 'sources/styles/variables.module.scss';

.container {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-around;
}

.total {
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  &:hover {
    & .totalNumber {
      color: $brandColor;
    }
  }
  &.active {
    & .totalNumber {
      color: $brandColor;
    }
  }
}

.totalNumber {
  font-size: 25px;
  font-weight: 600;
  transition: all ease 0.2s;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  & span {
    transition: all ease 0.2s;
  }
  &:hover {
    & span {
      color: $brandColor;
    }
  }
  &.active {
    & span {
      color: $brandColor;
    }
  }
}

.label {
  color: $black;
  font-size: 16px;
  text-align: center;
}

.charts {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  flex: 1;
}

.count {
  font-weight: 600;
}
