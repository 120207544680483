.container {
  margin: 0.5rem 0;
  position: relative;

  .image {
    width: auto;
    height: auto;
    max-width: 30vw;
    max-height: 40vh;
  }
}
