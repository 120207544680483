@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';

.tabs{
  display: flex;
  gap: 8px;
  margin-top: 25px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;

  @include _600 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
}

.tabsInner{
  display: flex;
  gap: 8px;
}

.tab{
  color: #222222;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #BFBFBF;
  border-radius: 100px;
  cursor: pointer;

  &.activeTab{
    background: $brandColor;

    color: #fff;
  }

  @include _600 {
    text-align: center;
    font-size: 13px;
    padding: 10px 10px;
  }
}