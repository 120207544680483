.uploadFromOutside {
  width: 400px;
}

.linkInput {
  width: 400px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-inline-end: 6px !important;
  border-radius: 10px !important;
  border-color: #dcdcdc !important;
  ::placeholder {
    color: #232631 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 130% !important;
  }
}

.or {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  width: 400px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #6a6a6a;
}

.videoInfo {
  margin: 10px 0;
}

.videoPreview {
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 0;
  transition: 0.3s all;
  opacity: 0;
}

.videoPreviewOpen {
  overflow: hidden;
  height: 250px;
  opacity: 1;
}
.subContent {
  display: flex;
  justify-content: space-between;
}

.linksContainer {
  margin-top: 10px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
