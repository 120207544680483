.pickers {
  display: flex;
  align-items: center;
  gap: 20px;
}

.picker {
  border-radius: 10px !important;
  height: 48px !important;
  width: 100%;
}

.error {
  margin-top: 5px;
  color: red;
}
