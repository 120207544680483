@import './variables.module.scss';
@import '../responsive.scss';

* {
  font-family: 'Noto Sans', sans-serif !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  background-color: $bgColor;
}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  background-color: $bgColor;
}

* {
  unicode-bidi: embed;
}

@mixin loginImageContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

@mixin loginContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  h1 {
    text-align: center;
    padding-bottom: 100px;
    padding-top: 20px;

    @include _768 {
      font-size: 34px !important;
    }

    @include _600 {
      font-size: 26px !important;
      padding-bottom: 50px;
    }

    @include _480 {
      font-size: 22px !important;
      padding-bottom: 25px;
    }
  }
}

.details-select {
  .ant-select-selector {
    border-radius: 8px !important;
    height: 44px !important;
    align-items: center !important;
  }
}

.form-item-with-select {
  .ant-form-item-explain-error {
    position: absolute;
    bottom: -23px;
  }
}

.container {
  .ant-card-body {
    @include _768 {
      padding: 0 !important;
    }
  }
}

.inviteTraineesSelect {
  margin-top: 0 !important;

  .ant-select-selector {
    border-radius: 6px !important;
  }

  .ant-select-selection-overflow {
    min-height: 40px !important;
  }
}

.form-item-invite-trainee-email {
  label {
    &::before {
      display: none !important;
    }
  }

  :disabled {
    background-color: rgba(239, 239, 239, 0.3) !important;
    color: inherit !important;
    cursor: default !important;
  }
}

@mixin programCreationButton {
  background-color: $brandColor !important;
  font-size: 13px !important;
  border-radius: 6px !important;
  height: 40px !important;
  margin-top: 0 !important;
}

@mixin createNewButton {
  background-color: $bgColor !important;
  border-color: #232631 !important;
  color: #232631 !important;
  border-radius: 5px !important;
  padding: 10px 18px !important;
  height: auto !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 130% !important;

  &:hover,
  &:focus {
    border-color: #232631 !important;
    color: #232631 !important;
    background-color: #e7e7e7 !important;
  }
  &:active {
    background-color: #dadcdd !important;
  }
}

@mixin listTitle {
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
}

@mixin listItemTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
}

@mixin listItemSubTitle {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

@mixin loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tabs-nav-list {
  border-bottom: 1px solid #bfbfbf !important;
}

.ant-tabs-tab {
  padding: 0;

  & > div {
    height: 100%;

    & > span {
      height: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      gap: 3px;
      align-items: center;
    }
  }

  &.ant-tabs-tab-active > div {
    & > span {
      color: $brandColor !important;

      & > span {
        background: $brandColor;
        color: #fff;
      }
    }
  }
}

.ant-tabs-nav-list {
  width: 100%;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.paginationContainer {
  & .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include _768 {
      justify-content: center !important;
    }
  }

  & .ant-pagination-item {
    border-radius: 50px !important;
    border: 0 !important;
    background-color: transparent !important;
    & a {
      color: #344054 !important;
    }

    &.ant-pagination-item-active {
      background: $brandColor !important;

      & a {
        color: #fff !important;
      }
    }
  }

  & .ant-pagination-item-link {
    border: 0 !important;
    background-color: transparent !important;
  }

  & .ant-select-selector {
    border-radius: 15px !important;
  }
}

.inviteTraineeModal {
  width: 850px !important;

  & .ant-modal-header {
    background-color: $bgColor;
  }

  & .ant-modal-title {
    font-size: 18px;
    font-weight: 700;
    color: #232631;
  }

  & .ant-modal-close-x {
    color: #130f26;
  }

  .ant-modal-footer {
    background-color: $bgColor !important;
  }
}

.importTraineesCVSModal {
  .ant-modal-footer {
    display: none !important;
  }

  & .ant-modal-header {
    background-color: $bgColor;
  }

  & .ant-modal-title {
    font-size: 18px;
    font-weight: 700;
    color: #232631;
  }

  & .ant-modal-close-x {
    color: #130f26;
  }
}

.importTraineesCVSModal {
  width: 650px !important;

  & .ant-modal-body {
    & > div {
      height: 300px !important;
    }
  }
}

.csvLoader {
  & span {
    color: #004d98 !important;
    font-weight: 700 !important;
  }
}

.inviteTraineeModalSelector {
  & .ant-select-focused {
    box-shadow: 0 !important;
  }

  & input {
    &::placeholder {
      font-size: 13px !important;
      color: #6a6a6a !important;
    }

    &.ant-input:focus {
      box-shadow: none !important;
    }

    padding: 12px !important;
    font-size: 14px !important;
    border-radius: 6px !important;
    border: 1px solid #dcdcdc !important;
    overflow: none !important;
  }
  & .ant-input-group-addon {
    display: none !important;
  }
}

.ant-modal-content {
  border-radius: 10px !important;
}

.switchUI {
  &.ant-switch-checked {
    background-color: $brandColor !important;
  }
}

.checkboxUI {
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $brandColor !important;
    border-color: $brandColor !important;
  }
}

.checkboxLightLabel {
  & > span {
    font-weight: 400 !important;
    color: #6a6a6a !important;
    font-size: 13px !important;
  }
}

.formInput {
  & > div {
    display: flex !important;
    flex-direction: column !important;
    max-width: 100% !important;
    width: 100% !important;
    & div {
      text-align: start !important;
      max-width: 100% !important;
      width: 100% !important;
      & label {
        margin-bottom: 0 !important;
      }
    }
  }
}

.ant-pagination-item-link,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  title: none !important;
}

.ant-select-dropdown {
  border-radius: 10px !important;
}

.ant-select-item-option-active {
  background-color: $bgColor !important;
}

.ant-select-item-option-selected {
  background-color: $brandColor !important;

  & .ant-select-item-option-content {
    color: #fff !important;
  }
}

.models {
  display: none !important;
}

.ant-tag-checkable {
  font-size: 15px !important;
  padding: 5px 10px !important;
  border-radius: 8px !important;

  &:not(.ant-tag-checkable-checked) {
    &:hover {
      color: $brandColor !important;
    }
  }

  &.ant-tag-checkable-checked {
    background-color: $brandColor !important;
  }
}

.tags-selector {
  & .ant-select-selector {
    border-radius: 10px !important;
  }

  & .ant-select-selection-item {
    border-radius: 10px !important;
  }
}

.report-collapse {
  & thead {
    & th {
      background-color: #fff !important;
    }
  }

  &.ant-collapse {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border: 1px solid $borderColor !important;
  }

  & .ant-collapse-header {
    padding: 25px 25px 25px !important;
    padding-right: 40px !important;
    border-bottom: 1px solid $borderColor !important;
  }

  & .ant-collapse-item {
    border: none !important;
  }

  & .ant-collapse-content {
    padding-left: 13px !important;
    padding-right: 13px !important;
    border-top: 0 !important;
    border-bottom: 0.5px solid $borderColor !important;
  }
}

.modal-title {
  font-size: 18px;
  font-weight: 700;
  color: #232631;
  margin-bottom: 0;
}

.ant-tabs-nav-list {
  & .ant-tabs-tab {
    &.ant-tabs-tab-active {
      & .ant-tabs-tab-btn {
        color: $brandColor !important;
      }

      & > div > span {
        color: $brandColor !important;
      }
    }
  }
}

.ant-tabs-ink-bar {
  background: $brandColor !important;
}

.autoComplete232232 {
  background-color: red;
}
