@import 'sources/styles/variables.module.scss';

.selectedTemplate {
  padding: 16px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid $borderColor;
}

.selectedTemplateWithoutBorder {
  border: 0 !important;
}

.selectedTemplateHead {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.selectedTemplateTitle {
  display: flex;
  gap: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  & > div:first-child {
    color: #232631;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
  }
}
