.phoneInput {
  direction: ltr;
  input {
    direction: ltr;
    padding: 4px 5px !important;
    border: 1px solid #d9d9d9;
    transition: border-color 0.2s, box-shadow 0.2s;
    padding: 0 10px;
    border-radius: 5px !important;
    padding-bottom: 2px;
    height: 38px !important;

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
      transition: border-color 0.3s, box-shadow 0.3s;
    }
  }
}

.inputContainer {
  & > div {
    display: flex !important;
    flex-direction: column !important;

    & > div {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: flex-start;

      & label {
        margin-bottom: 0 !important;
      }
    }
  }
}
