.videoContainer {
  display: flex;
  margin-inline-end: 10px;
}

.videoLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 366px;
}
