.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.queriesLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  margin-bottom: 10px;
}

.subtitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: #232631;
  margin-bottom: 0;
}
