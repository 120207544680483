@import 'sources/styles/variables.module.scss';

.link {
  color: $brandColor;
  font-size: 14px;
  font-weight: 500;

  span {
    margin-inline-start: 5px;
  }
}
