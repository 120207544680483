.imageUploadForm {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-bottom: 20px;
  height: 112px;

  > span {
    width: auto;
  }

  > p {
    flex: 1;
    font-size: 14px;
  }

  :global(.ant-upload-list-item-actions a) {
    display: none;
  }

  :global(.ant-upload-list > div:nth-child(2)) {
    display: none;
  }
}

.loading {
  width: 112px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload {
  border-radius: 50% !important;
  & > div {
    width: 153px !important;
    height: 153px !important;
    border-radius: 50% !important;

    & > div {
      border-radius: 50% !important;
      width: 153px !important;
      height: 153px !important;
      & > div {
        border-radius: 50% !important;
        padding: 0 !important;
        overflow: hidden !important;
      }
    }
  }

  & img {
    object-fit: cover !important;
  }
}

.img {
  width: 100%;
  height: 100px;
  min-width: 153px;
  min-height: 153px;
  object-fit: cover !important;
}

.container {
  overflow: hidden;
  border-radius: 50%;
  position: relative;

  &:hover {
    & .hoverContainer {
      opacity: 1;
    }
  }
}

.hoverContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all ease 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editIcon {
  color: #fff;
  font-size: 20px;
}
