.datePicker {
  height: 40px;
  width: 445px;
}

.container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}
