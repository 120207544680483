.actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.input,
.select {
  min-width: 200px !important;

  &.error {
    border: 1px solid red !important;
  }
}

.followUpSelect {
  min-width: 200px !important;

  &.error {
    & > div {
      border: 1px solid red !important;
    }
  }
}

.followUpInputContainer {
  position: relative;
}

.errorAbs {
  position: absolute;
  bottom: -18px;
  font-size: 12px;
  color: red;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
