.btns {
  display: flex;
  gap: 10px !important;
  align-items: center;
  justify-content: flex-end;
}

.phoneInput {
  margin-bottom: 20px;
}

.checkbox {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 8px !important;

  & span {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #2326319a !important;
  }
}

.profileSelect {
  width: 100% !important;
}

.checkboxes {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 20px;
}

.item {
  margin-bottom: 20px;
}

.label {
  color: #6a6a6a;
  font-size: 13px;
}
