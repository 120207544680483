@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.languageModal {
  margin-top: 1px !important;
  direction: ltr !important;

  & > div:nth-child(2) {
    & > button {
      direction: ltr !important;
      // border: 1px solid red !important;
      top: 10px;
      right: 10px;
      span > span {
        direction: ltr !important;
        font-size: 20px;
        color: #130f26;
      }
    }
    border-radius: 10px;
    div:nth-child(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
    }
    div:nth-child(2) {
      // border: 1px solid red;
      background-color: $bgColor;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 26px !important;
      padding-bottom: 26px !important;
    }
  }
}

.langSelect {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  margin-top: 1px;
  direction: ltr !important;

  label {
    direction: ltr !important;
    width: 230px;
    display: flex;
    align-items: center;
    text-align: center;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    padding-top: 11px;
    padding-bottom: 14px;
    padding-inline-start: 11px;
    margin-bottom: 12px;
    span:last-child div span {
      font-weight: 500 !important;
      font-size: 13px !important;
      line-height: 18px !important;
      margin-inline-start: 6px;
    }
  }
}
