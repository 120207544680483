.error {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
  gap: 10px;
  padding: 0 10px;
  text-align: center;

  &.card {
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 100;
  }

  & span {
    color: inherit !important;
  }
}

.btnsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn {
  padding: 0 !important;
  height: 25px !important;
  width: 25px !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}
