.customInput {
  & > div {
    padding: 0 10px;
    border-radius: 8px !important;
    padding-bottom: 2px;
    height: 38px !important;

    & > span {
      display: flex;
      align-items: center;
    }
  }

  &.big {
    & > div {
      height: 48px !important;
    }
  }
}
