@import 'sources/styles/variables.module.scss';

.toolBody {
  & p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  & img {
    max-width: 100%;
    height: 100%;
  }
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.subtitle {
  font-size: 16px;
}

.pic {
  margin-bottom: 5px;
  max-width: 150px;
}

.registerBtnContainer {
  display: flex;
  margin-top: 10px;
}

.registerBtn {
  background-color: $brandColor;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.eventDay {
  font-style: italic;
}

.publishContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemContainer {
  margin-bottom: 10px;
}
