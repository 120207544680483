@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.card {
  margin-inline-start: 110px !important;
  margin-inline-end: 110px !important;
  position: relative;
  border: 0 !important;
  width: 360px;
  background-color: transparent !important;
  max-width: 400px;
  width: 100%;

  @include _768 {
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
  }

  @include _400 {
    max-width: 320px;
  }

  .title {
    color: #fff;
    margin-bottom: 15px;
  }

  .titleDark {
    color: $black;
  }

  .text {
    margin-bottom: 25px;
    color: #fff;
  }

  .textDark {
    color: $black;
  }

  .form {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: -24px;
    padding: 24px;

    .textContainer {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .changePhone {
    color: $brandColor;
    text-decoration: underline;
    font-size: 14px;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: #0b87d9;
    }
  }
}
