.table {
  background-color: transparent !important;

  & thead {
    & th {
      color: #8c8c8c !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      padding-right: 5px !important;
      padding-left: 5px !important;

      &::before {
        display: none !important;
      }
    }
  }

  & tr {
    & td {
      background-color: transparent !important;
      padding-right: 5px !important;
      padding-left: 5px !important;
    }
  }
}

.nameCol {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name {
  color: #232631;
  font-weight: 600;
  font-size: 13px;
}

.text {
  color: #232631;
  font-weight: 500;
  font-size: 13px;
}

.progressContainer {
  max-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.details {
  cursor: pointer;
  color: #80838c;
  font-size: 13px;
  font-weight: 500;
}

.arrow {
  transition: all ease 0.2s;

  &.active {
    transform: rotate(180deg);
  }
}
