@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-bottom: 130px;

  .logo {
    width: 60px;
    height: 60px;
  }

  .logoTitle {
    margin-inline-end: 10px;
    margin-inline-start: 10px;
    margin-bottom: 0 !important;
    color: #fff;
  }

  .logoTitleDark {
    color: $black;
  }

  @include _480 {
    margin-bottom: 80px;
  }
}
