.contentSelector {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.contentSelectText {
  text-align: center;
  color: #536471;
  font-size: 14px;
  margin-bottom: 20px;
}

.toolSelectOptions {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.toolSelectOption {
  cursor: pointer;
  background-color: #e5f2fa;
  margin-inline-end: 12px;
  padding: 10px 12px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  display: flex;
  align-items: center;
  gap: 5px;
}