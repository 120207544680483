.container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.block {
  display: flex;
  align-items: center;
  gap: 15px;

  &.sm {
    gap: 8px;
  }
}

.uploadBtn {
  padding: 10px !important;
  max-height: 42.2px;
}

.btn {
  border: 1px solid #bfbfbf;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 20px;
  cursor: pointer;
  color: #222222;
}

.resetBtn {
  & span {
    text-decoration: underline !important;
  }
}
