.vars {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.varsRtl {
  left: 0;
  right: auto;
}
