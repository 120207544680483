.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #dbdcef;
  border-right-color: #0181f8;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}
