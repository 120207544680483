@import 'src/sources/styles/variables.module.scss';

.menu {
  width: 348px !important;
  min-width: 300px !important;
}

.list {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.program {
  padding: 15px !important;
  display: flex;
  margin: 0 !important;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;

  & > span:last-child {
    text-overflow: ellipsis !important;
    overflow: hidden;
  }
}

.title {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reset {
  color: $brandColor;
  cursor: pointer;
}
