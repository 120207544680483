.arrow {
  transition: all ease 0.2s;
  &.opened {
    transform: rotate(180deg);
  }
}

.btnBody {
  display: flex;
  align-items: center;
  gap: 10px;
}
