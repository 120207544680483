@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';

.step {
  padding-inline-start: 0 !important;

  & > div > div:nth-child(2) {
    background-color: $bgColor !important;
    margin-inline-end: 0 !important;
    color: #8b8b8b !important;
    width: 26px !important;
    height: 26px !important;
    line-height: unset !important;
    position: relative;
    z-index: 20 !important;
  }

  & > div > div:first-child {
    z-index: 10 !important;
    padding: 0 !important;

    &::after {
      background-color: #d4d8db !important;
      height: 2px !important;
      left: 0 !important;
      top: 10px !important;
    }
  }
  & > div > div:last-child > div {
    color: #888b92;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 26px !important;
  }
}
.nextStep {
  & > div > div:first-child::after {
    background-color: $brandColor !important;
    border: 1px solid $brandColor !important;
  }

  & > div > div:nth-child(2) {
    background-color: $brandColor !important;
    border: 1px solid $brandColor !important;
    margin-inline-end: 0 !important;
    color: #fff !important;
    width: 26px !important;
    height: 26px !important;
    line-height: unset !important;
    span {
      color: #fff !important;
    }
  }
}

.currentStep {
  & > div > div:nth-child(2) {
    background-color: $brandColor !important;
    border: 1px solid $brandColor !important;
    margin-inline-end: 0 !important;
    color: #fff !important;
    width: 26px !important;
    height: 26px !important;
    line-height: unset !important;
    span {
      color: #fff !important;
    }
  }
}

.stepRtl {
  & > div {
    & > div:first-child {
      margin-left: 0 !important;
      margin-right: 42px !important;
    }

    & > div:nth-child(2) {
      margin-right: 42px !important;
    }
  }
}
