.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resizer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btn {
  cursor: pointer;
  padding: 10px;

  &.disabled {
    cursor: default;
  }
}

.btnPrev {
  transform: rotate(180deg);
}

.text {
  font-size: 14px;
  color: #232631;
}

.select {
  & > div {
    background-color: transparent !important;
    border: 1px solid #bfbfbf !important;
    border-radius: 6px !important;
  }

  & svg {
    color: #232631;
  }
}
