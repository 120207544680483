.menu {
  max-height: 500px;
  min-width: 400px;
  overflow-y: auto;
  padding: 24px;
  border-radius: 15px;
  background-color: #fff;
  -webkit-box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.25);
}
