@import 'sources/styles/styles.scss';
@import 'sources/responsive.scss';

.container {
  @include loginContainer;
}

.imageContainer {
  @include loginImageContainer;
}

.imageWebP {
  background-image: url('/sources/images/login.webp');
}

.imageJpeg {
  background-image: url('/sources/images/login.jpeg');
}

.rtlBgImage {
  transform: scale(-1, 1);
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
