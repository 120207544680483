.checkboxes {
  display: flex;
  flex-direction: column;
}

.checkbox {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 8px !important;

  & span {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #2326319a !important;
  }
}

.formItem {
  margin-bottom: 0 !important;
}
