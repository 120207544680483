.container {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201);
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(181, 181, 181);
  }

  .empty {
    margin: auto;
  }
}
