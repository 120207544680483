@import 'sources/styles/variables.module.scss';

.linksContainer {
  display: flex;
  flex-direction: column;
  // gap: 10px;
}

.linksContainerPresent {
  margin-bottom: -10px;
}

.addMoreBtn {
  padding: 10px 0;
  & span {
    text-decoration: underline;
  }
}
