@import 'sources/responsive.scss';

.absolute {
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100000000;
}

.relative {
  position: relative;
  z-index: 100000000;
}

.btn {
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 5px;
  background-color: #007acb49;
  transition: background-color 0.2s ease;
  min-height: 50px;

  &.btnAbsolute {
    width: 120px;
  }

  .btnText {
    color: #fff;
    font-size: 12px;
    text-align: center;

    @include _480 {
      display: none;
    }
  }

  &:hover {
    background-color: #007acb75;
  }
}

.dropdown {
  position: absolute;
  left: 72px;
  transform: translateY(2000%);
  background-color: #0d2241;
  width: 120px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s ease;

  @include _480 {
    left: 52px;
  }
}

.option {
  color: #fff;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 12px;
  position: relative;

  &:hover {
    background-color: #007acb33;
  }
}

.optionRtl {
  text-align: right;
}

.activeOption {
  background-color: #007acb75;
}

.dropdownRtr {
  right: 72px;
  left: auto;

  @include _480 {
    right: 52px;
  }
}

.dropdownVisible {
  opacity: 1;
  transform: translateY(-100%);

  &.dropdownWithAbsolute {
    z-index: 1;
    transform: translateY(0);
    top: 52px;
    right: 0;
    left: auto;

    &.dropdownRtr {
      right: auto;
      left: 0;
    }
  }
}

.langPic {
  color: #fff;
}
