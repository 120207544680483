.idContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}

.copyBtn {
  cursor: pointer;
  font-size: 13px !important;
}
