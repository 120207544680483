.customMessage {
  display: flex;
  justify-content: flex-end;
  padding-inline-end: 54px !important;
  padding-top: 25px !important;
  & > div {
    padding: 16px;
    padding-inline-end: 10px;
    border-radius: 7px;
    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    color: #232631 !important;
    margin-bottom: 3px;
    width: 11px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.error {
  & > div {
    background-color: #ffebea;
    border: 1px solid #eb5c4b;
  }
}

.success {
  & > div {
    background-color: #dcf2e8;
    border: 1px solid #49aa7b66;
  }
}
.info {
  & > div {
    background-color: #dbe6fb;
    border: 1px solid #6194f466;
  }
}
.warn {
  & > div {
    background-color: #f9efd9;
    border: 1px solid #fdbf4666;
  }
}
