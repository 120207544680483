@import 'sources/styles/styles.scss';
@import 'sources/responsive.scss';

.newProgramButton {
  @include createNewButton;
}

.programsListTitle {
  @include listTitle;
}

.controlsTop {
  max-width: 1090px;
  width: 100%;
  margin: 20px auto 25px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @include _850 {
    flex-direction: column;
    gap: 10px;
  }
}

.titleWithSearch {
  width: 600px;
  display: flex;
  align-items: center;
  gap: 32px;

  @include _850 {
    flex-direction: column;
    gap: 10px;
  }
}

.search {
  width: 300px;

  @include _400 {
    width: 200px;
    & input {
      font-size: 12px !important;
    }
  }
}

.more {
  border-radius: 10px !important;
  overflow: hidden !important;
  & > li {
    min-width: 90px !important;
    padding-top: 7px;
    padding-bottom: 7px;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
