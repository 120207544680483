.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  font-size: 15px;
  font-weight: 500;
}

.select {
  min-width: 300px;

  & > div {
    border-color: #bfbfbf !important;
    background-color: transparent !important;

    & span {
      color: #626262 !important;
    }
  }
}
