.notification {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  padding: 5px 12px;
  background-color: #f4f4f4;
  border-radius: 6px;
  gap: 25px;
  margin-bottom: 16px;
  & > div:first-child {
    display: flex;
    gap: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 4px;
  }
}

.mainLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notificationPlaceholder {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  line-break: anywhere;
}

.btns {
  display: flex;
  align-items: center;
  gap: 5px;
}

.img {
  height: 60px;
  border-radius: 10px;
}

.user {
  font-style: italic;
}
