.btn {
  width: 35px !important;
  height: 35px !important;
  padding: 0 !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  & span {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  &.btnDanger {
    color: rgb(255, 0, 0) !important;
    border-color: rgb(255, 0, 0) !important;
  }

  &.small {
    width: 30px !important;
    height: 30px !important;
    font-size: 14px !important;
  }
}
