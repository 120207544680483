.editor {
  position: relative;
  border: 1px solid #fff;
  transition: all ease 0.2s;
}

.vars {
  position: absolute;
  bottom: 20px;
  right: 5px;
  z-index: 1000;
}

.varsRtl {
  left: 5px;
  right: auto;
}

.error {
  border: 1px solid red;
}
