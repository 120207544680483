@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';

.subtitle {
  font-size: 12px !important;
  color: #232631 !important;
  font-weight: 400 !important;
}

.nameCol {
  display: flex;
  align-items: center;
  gap: 8px;
}

.image {
  width: 32px;
  border-radius: 50px;
}

.text {
  color: #232631 !important;
  font-size: 13px !important;
  font-weight: 500 !important;

  @include _600 {
    font-size: 11px !important;
  }
}

.subtext {
  font-size: 12px;
  color: #6a6a6a;

  @include _600 {
    font-size: 10px !important;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sendBtn {
  background-color: $brandColor !important;
  border: 0 !important;
  color: #fff !important;
  padding: 12px 25px !important;
  border-radius: 6px !important;
  height: auto !important;
  min-height: 48px;

  &.disabledBtn {
    opacity: 0.7;
  }
}

.checkbox {
  font-weight: 500;
}
