@import 'sources/styles/variables.module.scss';

.modal {
  min-width: 800px;

  @media (max-width: 800px) {
    min-width: auto;
  }
}

.infoContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: start;
}

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search {
  max-width: 200px;
}

.textItem {
  margin-bottom: 10px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
}

.message {
  font-style: italic;
}

.sent {
  background-color: orange;
  color: #fff;
  border: 1px solid rgb(209, 136, 0);
  display: block;
  border-radius: 20px;
  padding: 0 5px;
}

.statusHeaderCol {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.emptyText {
  padding: 40px 0;
}

.fullNameCell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tableError {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox {
  & > span {
    & > span {
      cursor: default !important;
      border-color: $brandColor !important;
      background-color: transparent !important;

      &::after {
        border-color: $brandColor !important;
      }
    }
  }
}
