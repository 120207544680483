@import 'sources/responsive.scss';
@import 'sources/styles/styles.scss';

.templateList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 10px;
  padding-inline-end: 5px;

  @include _979 {
    grid-template-columns: repeat(3, 1fr);
  }

  @include _680 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include _480 {
    grid-template-columns: 1fr;
  }
}

.listWrapper {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201);
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(181, 181, 181);
  }
}

.spinWrapper > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.templateEdit {
  cursor: default;
}

.addTemplate {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232631;
  margin-top: 0 !important;
  margin-inline-end: 5px;
  background-color: #1649970d;
  border: 1px dashed #16499726;
  gap: 12px;
  padding: 24px;
  border-radius: 6px;
  transition: border 0.2s ease;
  cursor: pointer;
  height: 67px !important;

  &:hover {
    border: 1px dashed #91acce;
    background-color: #eaf3ff;
  }

  > div:first-child {
    font-size: 20px;
  }

  > div:last-child {
    font-size: 13px;
  }
}

.blockWrapper {
  &:nth-child(5) {
    margin-top: 10px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #000;
}

.btn {
  @include programCreationButton;
  color: #fff !important;
}

.search {
  margin-bottom: 10px;
  & > div {
    display: none !important;
  }
}

.searchInput {
  width: 372px !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
}
