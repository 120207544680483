@mixin _1170 {
  @media (max-width: 1170px) {
    @content;
  }
}
@mixin _1024 {
  @media (max-width: 1023px) {
    @content;
  }
}
@mixin _979 {
  @media (max-width: 979px) {
    @content;
  }
}
@mixin _850 {
  @media (max-width: 850px) {
    @content;
  }
}
@mixin _768 {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin _680 {
  @media (max-width: 680px) {
    @content;
  }
}
@mixin _600 {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin _480 {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin _400 {
  @media (max-width: 400px) {
    @content;
  }
}
@mixin _350 {
  @media (max-width: 350px) {
    @content;
  }
}

@mixin h_900 {
  @media (max-height: 900px) {
    @content;
  }
}

@mixin h_800 {
  @media (max-height: 800px) {
    @content;
  }
}

@mixin h_750 {
  @media (max-height: 750px) {
    @content;
  }
}

@mixin h_700 {
  @media (max-height: 700px) {
    @content;
  }
}

@mixin h_600 {
  @media (max-height: 600px) {
    @content;
  }
}
