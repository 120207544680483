@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';
@import 'sources/styles/styles.scss';

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirmLoad {
  margin-top: 10px;
  & > div:nth-child(2) > div > div > div:last-child {
    direction: ltr;
    button:last-child {
      margin-inline-start: 0;
      margin-inline-start: 8px;
    }
  }
}

.addTemplate {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}

.container {
  margin-top: 20px;
}

.link {
  color: $brandColor;
  font-size: 16px;
  font-weight: 500;

  span {
    margin-inline-start: 5px;
  }
}

.block {
  padding: 20px 0;
  border-radius: 12px;

  @include _400 {
    padding: 5px;
    margin-top: 5px;
  }
}

.title {
  color: #000;
  margin-bottom: 0 !important;
}

.btn {
  margin-top: 20px !important;
  background-color: $brandColor !important;
  font-size: 13px !important;
  border-radius: 6px !important;
  height: 40px !important;

  &:disabled {
    color: #fff !important;
    opacity: 0.7;
  }
}

.blockWrapper {
  &:nth-child(5) {
    margin-top: 10px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  @include programCreationButton;
  color: #fff !important;

  border: 1px solid #232631 !important;
  padding: 5px 12px !important;
  background-color: transparent !important;
  color: #232631 !important;
  font-size: 12px !important;

  height: auto !important;
}

.deleteBtn {
  background-color: #ff4d4f !important;

  &:hover {
    background-color: #ff7875 !important;
  }
}

.buttonDelete {
  font-size: 13px !important;
  border-radius: 6px !important;
  height: 40px !important;
}

.controls {
  display: flex;
  justify-content: flex-end;
}

.programInfo {
  margin-bottom: 30px;
}

.titleContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}
