.personalInformation {
  margin-top: 26px;
  gap: 20px;
  flex: 1;

  & > div {
    width: 100%;
  }
}

.form {
  flex: 1;
}

.topInputs {
  display: flex;
  gap: 10px;
  flex: 1;
  width: 100%;
}

.block {
  background-color: #fff;
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  margin-top: 10px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

.content {
  display: flex;
  gap: 20px;
}

.inputDouble {
  width: 100%;
}
