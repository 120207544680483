@import 'sources/styles/variables.module.scss';

.noGoals {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .message {
    width: 400px;
    color: #6a6a6a;
    margin-bottom: 20px;
  }
}

.addGoalLinkContainer {
  margin-top: 10px;
}

.addGoalLink {
  & span {
    text-decoration: underline !important;
  }
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modalTitle {
  font-weight: 700;
  font-size: 18px;
}
