@import 'sources/styles/variables.module.scss';

.container {
  margin: 30px 0;
}

.remindersContainer {
  background-color: #fff;
  padding: 24px 24px 10px;
  border-radius: 10px;
  border: 1px solid $borderColor;
  border-radius: 10px;
}

.loading {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  text-align: center;
  margin: 20px 0 30px;
  font-size: 15px;
  line-height: 140%;
  font-weight: 500;
  color: rgb(103, 103, 103);
}
