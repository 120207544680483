.container {
  margin-top: 30px;
}

.dayInfo {
  margin-bottom: 10px;
  h5 {
    margin-bottom: 5px !important;
  }
}

.titleWithButton {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.changeDataDisable {
  color: #000000;
  cursor: default;
}

.btns {
  display: flex;
  align-items: center;
}
