@import 'src/sources/styles/variables.module.scss';

.default {
  color: #232631 !important;
  border: 1px solid #232631 !important;
  border-radius: 5px !important;
  padding: 12px 24px !important;
  height: auto !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  background-color: transparent !important;
}

.link {
  background-color: transparent !important;
  border: none !important;
  color: #164997 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 130% !important;
  box-shadow: none !important;

  &:hover {
    color: #4472b7 !important;
  }
}

.primary {
  background-color: $brandColor;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 13px 24px !important;
  height: auto !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
}

.small {
  padding: 6px !important;
}

.disabled {
  opacity: 0.6 !important;
}
