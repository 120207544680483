@import 'sources/responsive.scss';
@import 'sources/styles/styles.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1095px;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 100px;
}

.loaderDayContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.noNewsContainer {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  flex-direction: column;
}

.noNews {
  text-align: center;
  margin-bottom: 5px;
}

.search {
  max-width: 300px;
}
