@import 'sources/styles/variables.module.scss';

.ant-picker-cell {
  &:not(.ant-picker-cell-in-view) {
    & .calendar_dot {
      &.calendar_dot_hasContent {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.calendar {
  padding: 5px 10px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  width: 400px;

  & th {
    padding: 15px !important;
    text-align: center;
    font-style: bold !important;
  }
}

.calendar_day {
  padding: 2px;
  margin: 1px;
  position: relative;
  transition: all ease 0.2s;
  border: 3px solid transparent;
  border-radius: 6px;
  text-align: center;

  &:hover {
    border-color: $brandColor;
  }

  &.calendar_day_selected {
    background-color: $brandColor;
    color: #fff;

    & .calendar_dot {
      &.calendar_dot_hasContent {
        background-color: white;
      }
    }
  }
}

.calendar_today {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $brandColor;
  opacity: 0.25;
}

.calendar_dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto;
  margin-top: 5px;

  &.calendar_dot_hasContent {
    background-color: $brandColor;
  }
}
