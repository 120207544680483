@import 'sources/responsive.scss';
@import 'sources/styles/variables.module.scss';

.recentEntries {
  background-color: #fff;
  border-radius: 15px;
  padding: 32px;
  padding-bottom: 20px;
  margin-top: 24px;
  border: 1px solid $borderColor;

  @include _680 {
    padding: 10px;
    margin-top: 10px;
  }
}

.blockTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #232631;
}

.table {
  margin-top: 1px;
  th {
    background-color: #fff !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #8c8c8c !important;
    &::before {
      all: unset !important;
    }
  }
}

.nameCol {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.paginationContainer {
  margin-top: 10px;
}
